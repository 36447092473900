import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import React from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
export type LinkProps = MuiLinkProps & RouterLinkProps

const _Link = (props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => (
  <MuiLink ref={ref} {...props} component={RouterLink} />
)

export const Link = React.forwardRef(_Link)
export type AliceHomepageUrlProp = 'https://thisisalice.com' | '/' | '/manage'

// use the base MUI Link element for plain anchor elements, typically used to link offsite or open in a new tab
export const ExternalLink = MuiLink
