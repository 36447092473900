import { fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { SupportedLangCode } from '../../translations/types'
import { useCurrentUserQuery } from './gql/currentUser_gen'
import { RestUser } from './types'
import { USER_QUERY_KEY } from './useQueryUser'
import { useQueryClient } from '@tanstack/react-query'

const updateWithLanguagePref =
  (language: SupportedLangCode) =>
  (user: RestUser | undefined): RestUser | undefined => {
    if (!user) return undefined
    return {
      ...user,
      employee_profile: {
        ...user.employee_profile,
        language_preference: language,
      },
    }
  }

const patchUserLanguagePref = (language: SupportedLangCode) =>
  fetchRest<RestUser>('/api/user/set_language_preference', {
    method: 'PATCH',
    body: JSON.stringify({ language }),
  })
export const usePatchUserLanguagePref = () => {
  const queryClient = useQueryClient()
  return useMutationWithInvalidation(
    patchUserLanguagePref,
    {
      // optimistically update the user's language preference
      // so that the app will immediately re- render in the new language
      onMutate: (language: SupportedLangCode) => {
        queryClient.setQueryData<RestUser>(USER_QUERY_KEY, updateWithLanguagePref(language))
      },
    },
    [USER_QUERY_KEY, useCurrentUserQuery.getKey()]
  )
}
