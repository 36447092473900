import * as React from 'react'
import { Outlet } from 'react-router'
import { StripeConnectComponentsProvider } from '../../externalServices/stripe/StripeConnectComponentsProvider'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'

export const EnableConnectComponents = () => {
  const { data: orgData } = useOrgDashboardQuery()
  const org = orgData?.organization
  if (!org) return null

  return (
    <StripeConnectComponentsProvider orgId={org.id}>
      <Outlet />
    </StripeConnectComponentsProvider>
  )
}
