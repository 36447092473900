import { SectionHeading } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { ConnectBilling } from '../billing/ConnectBilling'

type BillingSectionProps = {
  org: OrganizationDetailFragment | undefined | null
}
export const BillingSection = ({ org }: BillingSectionProps) => {
  if (!org) return null
  return (
    <>
      <SectionHeading to="/manage/accounts" gutterBottom>
        Billing
      </SectionHeading>
      <ConnectBilling org={org} />
    </>
  )
}
