import { Box, Callout, Collapse, Container, Link, PII, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLoginPath } from '../../../auth/useLoginPath'
import { useCurrentUserOnboardingQuery } from '../gql/currentUserOnboarding_gen'
import { CreateOrgAdminForm } from './CreateOrgAdminForm'

const LoginCallout = () => {
  const loginPath = useLoginPath()

  return (
    <Callout gutterBottom>
      <FormattedMessage id="onboarding.already_have_account" />
      <Link to={loginPath}>
        {' '}
        <FormattedMessage id="organization.onboarding.login" />{' '}
      </Link>
    </Callout>
  )
}

export const CreateOrgAdminStep = () => {
  const { data: onboardingUserData, isLoading: disabled } = useCurrentUserOnboardingQuery()
  const onboardingUser = onboardingUserData?.currentUser
  return (
    <Container>
      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="organization.onboarding.setup_account_heading" />
      </Typography>
      {onboardingUser && (
        <Typography gutterBottom>
          <PII>
            <strong>
              {onboardingUser.firstName} {onboardingUser.lastName}
            </strong>{' '}
            ({onboardingUser.email})
          </PII>
        </Typography>
      )}
      <Collapse in={!onboardingUser}>
        <Box component={Typography} gutterBottom>
          <LoginCallout />
          <FormattedMessage id="organization.onboarding.setup_account_instructions" />
        </Box>
        <CreateOrgAdminForm disabled={disabled} />
      </Collapse>
    </Container>
  )
}
