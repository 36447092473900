import * as React from 'react'
import { Authenticate } from '../auth/AuthenticateRoutes'
import { RouteChrome } from '../routes/RouteChrome'
import { OrgAdminRedirect } from './OrgAdminRedirect'
import { OrgDashboardNavigation } from './OrgDashboardNavigation'
import { OrgRouteTitle } from './OrgRouteTitle'

export const OrgDashboardRouteChrome = () => (
  <Authenticate requiredProfile={['organization_admin', 'employer_admin']}>
    <OrgAdminRedirect>
      <RouteChrome
        type="admin"
        root={{ route: '/manage', label: 'Admin' }}
        Nav={OrgDashboardNavigation}
        pageTitle={<OrgRouteTitle />}
      />
    </OrgAdminRedirect>
  </Authenticate>
)
