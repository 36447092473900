import { Box, BoxProps, Link, Breadcrumbs as MuiBreadcrumbs, styled } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useLocation, useMatches } from 'react-router'
import { useIsEnrolling } from '../enrollment/useEnrollmentStatus'
import { UserWebRouteObject } from './normalizeRoutes'

export type BreadcrumbProps = {
  route: string
  label: React.ReactNode
}

export const Breadcrumb = styled(Link)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
  padding: '0.5em 0',
  textDecoration: 'none',
  fontWeight: 'bold',
}))

/**
 * This hook uses some routing configuration conventions baked into `UserWebRouteObject`
 * that provide a way for `useMatches` to find parent routes that have a particular label.
 * All routes that a) have a `handle.title` param and b) are parents of the current route
 * will be returned as `BreadcrumbProps` objects, ready for rendering.
 */
export const useBreadcrumbs = (breadcrumbs: Array<BreadcrumbProps>) => {
  const matchedBreadcrumbs = useMatches().reduce(
    (acc, match) => {
      const handle = match.handle as UserWebRouteObject['handle']
      // ignore pathless matches and wildcard matches
      if (!handle?.title) return acc

      acc.push({ route: match.pathname, label: handle.title })
      return acc
    },
    [...breadcrumbs]
  )

  return matchedBreadcrumbs
}

/**
 * This component displays text link breadcrumbs based on the current route. Using the logic of
 * `useBreadcrumbs` to determine the parent paths and their titles/labels, it displays breadcrumb
 * links as long as
 *
 * 1. The current route is not the root path (in which case there's nowhere to navigate to)
 * 2. The user is not currently enrolling - in that case, the user must follow the prescribed flow
 *    without trying to navigate elsewhere in the site.
 */
export const Breadcrumbs = ({ root, ...boxProps }: BoxProps & { root?: BreadcrumbProps }) => {
  const isEnrolling = useIsEnrolling()
  const location = useLocation()
  const isRootPath = location.pathname === root?.route
  const rootBreadcrumbs = React.useMemo(() => (root ? [root] : []), [root])

  const breadcrumbs = useBreadcrumbs(rootBreadcrumbs)

  if (isRootPath) return null
  if (breadcrumbs.length === 0) return null
  if (isEnrolling) return null

  return (
    <Box {...boxProps}>
      <MuiBreadcrumbs aria-label="breadcrumbs">
        {breadcrumbs.map(({ route, label }, idx, mappedBreadcrumbs) =>
          idx === mappedBreadcrumbs.length - 1 ? (
            label
          ) : (
            <Breadcrumb key={route} to={route}>
              {label}
            </Breadcrumb>
          )
        )}
      </MuiBreadcrumbs>
    </Box>
  )
}
