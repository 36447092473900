import { AddressEntity } from '../../user/types'

export const addressAttributesToAddressDisplay = (address: AddressEntity | null | undefined) => {
  if (!address) return null
  return {
    line1: address.line_1,
    line2: address.line_2,
    cityName: address.city,
    stateCode: address.state,
    zipcode: address.zipcode,
  }
}
