import { Button } from '@alice-financial/pretext-ui'
import React from 'react'

export const LogoUploadButton: React.FC<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ id, children, ...inputProps }) => {
  return (
    <>
      <input
        {...inputProps}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: 'none' }}
        id={`upload-logo-${id}`}
        type="file"
      />
      <Button variant="contained" component="label" htmlFor={`upload-logo-${id}`}>
        {children}
      </Button>
    </>
  )
}
