import * as Types from '../../../graphql/generated.types'

import {
  OrganizationDetailFragmentDoc,
  AliceCardProgramFragmentDoc,
  AliceCardFundingFragmentDoc,
  PaymentMethodConnectionFragmentDoc,
  PaymentMethodFragmentDoc,
  BillingSourceFragmentDoc,
  EmployerSummaryFragmentDoc,
  OnboardingStateFragmentDoc,
} from '../../../graphql/fragments/OrganizationFragment_gen'
import { PaymentCardFragmentDoc } from '../../../graphql/fragments/PaymentCardFragment_gen'
import { ErrorFragmentDoc } from '../../../graphql/fragments/ErrorFragment_gen'
import { BankingAccountFragmentDoc } from '../../../graphql/fragments/BankingAccountFragment_gen'
import { WorkLocationFragmentDoc } from '../../../graphql/fragments/WorkLocationFragment_gen'
import { AddressFragmentDoc } from '../../../graphql/fragments/AddressFragment_gen'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type OrgDashboardQueryVariables = Types.Exact<{ [key: string]: never }>

export type OrgDashboardQuery = {
  __typename?: 'Query'
  organization?: {
    __typename: 'Organization'
    id: number
    name: string
    commutingLaunchDate?: string | null
    logo?: { __typename?: 'Image'; url?: string | null; thumbnailUrl?: string | null } | null
    aliceCardProgram: {
      __typename?: 'AliceCardProgram'
      kycStatus: Types.AliceCardKycStatus
      hasFundingConnected: boolean
      cardIssuingStatus: Types.AliceCardIssuingStatus
      hasUnifiedBanking?: boolean | null
      companyAccount?: {
        __typename?: 'StripeCompanyAccount'
        id: number
        stripeConnectedAccountId?: string | null
        stripeFinancialAccountId?: string | null
      } | null
    }
    aliceCardFunding: {
      __typename?: 'AliceCardFunding'
      status: Types.AliceCardFundingStatus
      paymentMethodConnections?: Array<{
        __typename?: 'PaymentMethodConnection'
        id: number
        isCurrent: boolean
        isActive: boolean
        paymentMethod: {
          __typename?: 'PaymentMethod'
          id: number
          last4?: string | null
          descriptor: string
          status: Types.PaymentMethodStatus
          stripeSystemId: string
        }
      }> | null
      availablePaymentMethods?: Array<{
        __typename?: 'PaymentMethod'
        id: number
        last4?: string | null
        descriptor: string
        status: Types.PaymentMethodStatus
        stripeSystemId: string
      }> | null
    }
    payrollConnection: {
      __typename?: 'PayrollConnection'
      payrollInstructionsCompletedAt?: string | null
      payrollConnectedAt?: string | null
      payrollPlatform?: {
        __typename?: 'PayrollPlatform'
        id: number
        name: string
        code: string
        permitsAdminReports: boolean
      } | null
    }
    billing?: {
      __typename?: 'Billing'
      hasUnifiedBilling: boolean
      hasBillingConnected: boolean
      billingWorkspace: Types.BillingWorkspace
      billingSource?:
        | {
            __typename: 'BankingAccount'
            id: number
            billingSystemId?: string | null
            name?: string | null
            cardType?: Types.BankCardType | null
            accountType?: Types.BankingAccountType | null
            platform: Types.BankingAccountPlatform
            accountLast4?: string | null
            error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
          }
        | {
            __typename: 'PaymentCard'
            id: number
            billingSystemId?: string | null
            brand: string
            expMonth: number
            expYear: number
            last4: string
            error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
          }
        | null
    } | null
    employers: Array<{
      __typename: 'Employer'
      id: number
      name?: string | null
      marketingFriendlyName?: string | null
      inviteCode: string
      onboardingStatus?: Types.EmployerOnboardingStatus | null
      aliceCardFunding: {
        __typename?: 'AliceCardFunding'
        status: Types.AliceCardFundingStatus
        paymentMethodConnections?: Array<{
          __typename?: 'PaymentMethodConnection'
          id: number
          isCurrent: boolean
          isActive: boolean
          paymentMethod: {
            __typename?: 'PaymentMethod'
            id: number
            last4?: string | null
            descriptor: string
            status: Types.PaymentMethodStatus
            stripeSystemId: string
          }
        }> | null
        availablePaymentMethods?: Array<{
          __typename?: 'PaymentMethod'
          id: number
          last4?: string | null
          descriptor: string
          status: Types.PaymentMethodStatus
          stripeSystemId: string
        }> | null
      }
      billing?: {
        __typename?: 'Billing'
        hasUnifiedBilling: boolean
        hasBillingConnected: boolean
        billingWorkspace: Types.BillingWorkspace
        billingSource?:
          | {
              __typename: 'BankingAccount'
              id: number
              billingSystemId?: string | null
              name?: string | null
              cardType?: Types.BankCardType | null
              accountType?: Types.BankingAccountType | null
              platform: Types.BankingAccountPlatform
              accountLast4?: string | null
              error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
            }
          | {
              __typename: 'PaymentCard'
              id: number
              billingSystemId?: string | null
              brand: string
              expMonth: number
              expYear: number
              last4: string
              error?: { __typename?: 'Error'; message?: string | null; code?: string | null } | null
            }
          | null
      } | null
      employees: {
        __typename?: 'EmployeeConnection'
        reachableCount: number
        totalCount: number
        enrolledCount: number
        totalDeductions: number
        totalSavings: number
      }
    } | null>
    workLocations: Array<{
      __typename?: 'WorkLocation'
      id: number
      nickname?: string | null
      name: string
      latitude?: number | null
      longitude?: number | null
      logo?: { __typename?: 'Image'; url?: string | null; thumbnailUrl?: string | null } | null
      enrollmentMaterials?: { __typename?: 'EnrollmentMaterials'; zippedMaterialsUrl?: string | null } | null
      address?: {
        __typename?: 'AddressDisplay'
        isVerified: boolean
        isMailing: boolean
        line1: string
        line2?: string | null
        cityName: string
        stateCode: string
        zipcode: string
      } | null
    } | null>
    employees: {
      __typename?: 'EmployeeConnection'
      reachableCount: number
      totalCount: number
      enrolledCount: number
      totalDeductions: number
      totalSavings: number
    }
    onboardingState: {
      __typename?: 'OnboardingState'
      isAliceCardProgramActive: boolean
      isDoneWithOnboardingDocuments: boolean
      isAlreadyLaunched: boolean
      isBillingConnected: boolean
      isDoneWithPayrollInstructions: boolean
      isLogoPresent: boolean
      isOrgOnboardingSurveyComplete: boolean
      isLaunchingInFuture: boolean
      isLaunchingToday: boolean
      isPayrollConnected: boolean
      isPayrollConnectionComplete: boolean
      isReadyToLaunch: boolean
    }
  } | null
}

export const OrgDashboardDocument = `
    query OrgDashboard {
  organization {
    ...OrganizationDetail
  }
}
    ${OrganizationDetailFragmentDoc}
${AliceCardProgramFragmentDoc}
${AliceCardFundingFragmentDoc}
${PaymentMethodConnectionFragmentDoc}
${PaymentMethodFragmentDoc}
${BillingSourceFragmentDoc}
${PaymentCardFragmentDoc}
${ErrorFragmentDoc}
${BankingAccountFragmentDoc}
${EmployerSummaryFragmentDoc}
${WorkLocationFragmentDoc}
${AddressFragmentDoc}
${OnboardingStateFragmentDoc}`

export const useOrgDashboardQuery = <TData = OrgDashboardQuery, TError = Error>(
  variables?: OrgDashboardQueryVariables,
  options?: UseQueryOptions<OrgDashboardQuery, TError, TData>
) => {
  return useQuery<OrgDashboardQuery, TError, TData>(
    variables === undefined ? ['OrgDashboard'] : ['OrgDashboard', variables],
    fetchGql<OrgDashboardQuery, OrgDashboardQueryVariables>(OrgDashboardDocument, variables),
    options
  )
}

useOrgDashboardQuery.getKey = (variables?: OrgDashboardQueryVariables) =>
  variables === undefined ? ['OrgDashboard'] : ['OrgDashboard', variables]
