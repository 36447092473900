import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Card } from '@stripe/stripe-js'
import { useMutation } from '@tanstack/react-query'
import { BillableType, PaymentCardInput } from '../../graphql/generated.types'
import { useSetPaymentCardMutation } from '../../organization/gql/setPaymentCard_gen'
import { useCurrentUserOnboardingQuery } from '../../organization/onboarding/gql/currentUserOnboarding_gen'
import { useOrgDashboardQuery } from '../../orgDashboard/homepage/gql/orgDashboard_gen'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'

export type PaymentCardInputValues = {
  ownerType: BillableType
  ownerId: number
  address_zip: string
}

const tokenCardToPaymentCard = (tokenCard: Card): PaymentCardInput => {
  const { id, brand, exp_month, exp_year, last4 } = tokenCard
  return { id, brand, exp_month, exp_year, last4 }
}

type UseConnectPaymentCardMutationOptions = UseGqlMutationCallbacks
/**
 * mutation to set payment card - must be used inside <Elements> from @stripe/react-stripe-js
 */
export const useConnectPaymentCard = (mutationOptions?: UseConnectPaymentCardMutationOptions) => {
  const stripe = useStripe()
  const elements = useElements()
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    useMutationNotifier({}, mutationOptions),
    [useCurrentUserOnboardingQuery.getKey(), useOrgDashboardQuery.getKey()]
  )
  const { mutateAsync: setPaymentCard } = useSetPaymentCardMutation()

  return useMutation(({ address_zip, ownerId, ownerType }: PaymentCardInputValues) => {
    if (!elements || !stripe) throw new Error('Stripe elements not loaded')
    const cardElement = elements.getElement(CardNumberElement)
    if (!cardElement) throw new Error('Card element not found') // unrecoverable error - shouldn't ever happen though

    return stripe.createToken(cardElement, { address_zip }).then(({ token, error }) => {
      if (error) throw error // Stripe error, unrecoverable
      const { id, card } = token

      if (!card) throw new Error('No card found in token')
      return setPaymentCard({
        input: { ownerType, ownerId, token: { id, card: tokenCardToPaymentCard(card) } },
      })
    })
  }, mutationOptionsWithInvalidation)
}
