import { SubmitHandler, useForm } from 'react-hook-form'
import { LogoUploadButton } from '../LogoUploadButton'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { theme } from '@alice-financial/pretext-ui/theme/theme'
import { useUpdateOrganizationLogo } from './useUpdateOrganizationLogo'
import { useDeleteOrganizationLogo } from './useDeleteOrganizationLogo'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'
import { NULL_FILE_LIST } from '../../../utils/fileUtils'

type OrganizationLogoUploadFormProps = {
  org: OrganizationDetailFragment
}

export type OrganizationLogoUploadFormInputs = {
  logo: FileList | null
  id: number
}

export const OrganizationLogoUploadForm = ({ org }: OrganizationLogoUploadFormProps) => {
  const form = useForm<OrganizationLogoUploadFormInputs>({
    defaultValues: { logo: null, id: org.id },
  })

  const { setValue, register, watch, formState, handleSubmit } = form
  const logoChanged = Boolean(formState.dirtyFields.logo)
  const uploadedLogoValue = watch('logo')?.item(0)
  const logoValue = uploadedLogoValue ? URL.createObjectURL(uploadedLogoValue) : null
  // this shouldn't happen, but if the logo has changed, show null even if (stale) object has a logo url
  const displayLogo = logoChanged ? logoValue : org.logo?.url

  const removeLogo = () => {
    deleteOrganizationLogo({ id: org.id })
    setValue('logo', NULL_FILE_LIST)
  }

  const onSubmit: SubmitHandler<OrganizationLogoUploadFormInputs> = (values) => {
    const logo = values.logo?.item(0)
    if (!logo) return
    updateOrganizationLogo({ logo, id: values.id, logoChanged })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (logoValue) {
      removeLogo()
    }

    setValue('logo', e.target.files)
    onSubmit(form.getValues())
  }

  const { mutate: updateOrganizationLogo } = useUpdateOrganizationLogo({}, [useOrgDashboardQuery.getKey()])
  const { mutate: deleteOrganizationLogo } = useDeleteOrganizationLogo({}, [useOrgDashboardQuery.getKey()])

  const uploadButtonProps = {
    ...register('logo', { onChange: (e) => handleFileChange(e) }),
    id: org.id?.toString(),
  }

  // This form is slightly unusual
  // - it has no submit button; the logo is set on the backend as soon as it is uploaded
  // - the file input always starts out empty, but logo (which can point to either a temporary url for the uploaded file or the existing logo url)
  // is displayed regardless of whether one exists
  // - the remove button immediately deletes the logo on the backend and sets the file input to null
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3" color="primary">
              {displayLogo ? "Manage your organization's logo" : 'Add an organization logo'}
            </Typography>
            {displayLogo && (
              <Box display="flex" gap={2} mt={2}>
                {/* (not enabled at the moment) <Button type="reset" variant="outlined" color="error" onClick={() => removeLogo()}>
                  Remove
                </Button> */}
                <LogoUploadButton {...uploadButtonProps}>Update logo</LogoUploadButton>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                {displayLogo ? (
                  <Paper
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <img
                      src={displayLogo}
                      alt="Logo"
                      style={{ maxHeight: 120, maxWidth: theme.spacing(18) }}
                    />
                  </Paper>
                ) : (
                  <LogoUploadButton {...uploadButtonProps}>Upload logo</LogoUploadButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
