import { ActionLayout, Button, Grid, InlineInputController, InputLabel } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { AddressInput } from '../../../employeeDashboard/profile/address/AddressInput'
import { PayrollInput } from '../../PayrollInput'
import { OrgOnboardingFormValues, ProvisionalOrganization } from '../types'
import { apiValuesToFormValues, useManageProvisionalOrg } from '../useManageProvisionalOrg'

const DEFAULT_VALUES: OrgOnboardingFormValues = {
  legal_name: '',
  payroll_platform_id: '',
  line_1: '',
  line_2: '',
  city_name: '',
  state_code: '',
  zipcode: '',
  num_locations: 1,
  payroll_frequency: 'monthly',
  next_payday: '',
  last_day_of_pay_period: '',
  last_day_of_month: false,
  days_before_payday_submitted: 0,
  action: 'save',
}

/**
 * Wrapper around useForm that sets default values and resets the form when the provisional org changes.
 */
const useCompanyInfoForm = (provisionalOrg?: ProvisionalOrganization | null) => {
  const defaultValues = React.useMemo((): OrgOnboardingFormValues => {
    const values = provisionalOrg
      ? ({
          ...DEFAULT_VALUES,
          ...apiValuesToFormValues(provisionalOrg),
          action: DEFAULT_VALUES.action,
        } as OrgOnboardingFormValues)
      : DEFAULT_VALUES
    return values
  }, [provisionalOrg])

  const useFormReturn = useForm<OrgOnboardingFormValues>({ defaultValues })
  const { reset } = useFormReturn

  React.useEffect(() => {
    if (defaultValues === DEFAULT_VALUES) return
    reset(defaultValues)
  }, [provisionalOrg, defaultValues, reset])

  return useFormReturn
}

type CreateOrgFormProps = {
  disabled?: boolean
  provisionalOrg?: ProvisionalOrganization | null
}

/**
 * This form will create a new *provisional* organization.
 */
export const CompanyInfoForm = ({ disabled, provisionalOrg }: CreateOrgFormProps) => {
  const useFormReturn = useCompanyInfoForm(provisionalOrg)
  const { control, handleSubmit, setValue } = useFormReturn

  const { mutate: manageProvisionalOrg, isLoading: isUpdatingProvisionalOrg } = useManageProvisionalOrg(
    provisionalOrg?.id.toString()
  )

  const formDisabled = Boolean(disabled || provisionalOrg?.organization_id) // provisional org cannot be edited once organization is created
  const submitDisabled = Boolean(formDisabled || isUpdatingProvisionalOrg || provisionalOrg?.organization_id)

  return (
    <form data-testid="create-org" onSubmit={handleSubmit((values) => manageProvisionalOrg(values))}>
      <Grid container spacing={1} rowGap={2}>
        <Grid item xs={12} sm={8}>
          <InputLabel sx={{ display: 'block', marginBottom: 2 }} htmlFor="legal_name">
            Legal name
          </InputLabel>
          <InlineInputController
            control={control}
            name="legal_name"
            disabled={formDisabled}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel sx={{ display: 'block', marginBottom: 2 }} htmlFor="line_1">
            Business address
          </InputLabel>
          <AddressInput control={control} disabled={formDisabled} underscore />
        </Grid>
      </Grid>

      <PayrollInput disabled={formDisabled} {...useFormReturn} />

      <ActionLayout
        mb={0}
        primary={
          <Button
            name="action"
            type="submit"
            value="submit"
            disabled={submitDisabled}
            variant="contained"
            fullWidth
            onClick={() => setValue('action', 'submit')} // slight hack to get the value of the button into the submit handler
          >
            Submit
          </Button>
        }
        secondary={
          <Button
            name="action"
            type="submit"
            value="save"
            disabled={submitDisabled}
            variant="outlined"
            fullWidth
            onClick={() => setValue('action', 'save')}
          >
            Save for later
          </Button>
        }
      />
    </form>
  )
}
