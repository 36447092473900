import { fetchRest } from '@alice-financial/api'
import { useQuery } from '@tanstack/react-query'
import { ProvisionalOrganization } from './types'

const fetchProvisionalOrgs = (id?: string) =>
  fetchRest<Array<ProvisionalOrganization> | ProvisionalOrganization>(
    `/api/onboarding/provisional_orgs${id ? `/${id}` : ''}`
  ).then((results) => (Array.isArray(results) ? results[0] || null : results))

export const getQueryProvisionalOrgKey = (id?: string) => [`provisionalOrg`, id]
export const useQueryProvisionalOrgs = (id?: string) =>
  useQuery(getQueryProvisionalOrgKey(id), () => fetchProvisionalOrgs(id))
