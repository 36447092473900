import {
  Box,
  Chip,
  ChipProps,
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridRowParams,
  PII,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { dateStringFormat } from '../../../utils/formatters/dateFormat'
import {
  PageSizePagingContextProvider,
  PageSizePagingState,
  usePageSizePagingContext,
} from '../../../utils/paging/pageSizePaging/PageSizePagingContext'
import { isNotNull } from '../../../utils/typeUtils'
import { useEmployeeQuery } from '../../employeeDashboard/gql/employee_gen'
import { EmployeeInfoFragment } from '../../graphql/fragments/EmployeeInfoFragment_gen'
import { EmployeeAccountStatus, EmployeeRole } from '../../graphql/generated.types'
import { useAccountStatusFilter } from './AccountStatusFilter'
import { EmployeeFilter } from './EmployeeFilter'
import { useSearchFilter } from './EmployeeSearchFilter'
import { useOrgEmployeeListQuery } from './gql/orgEmployeeList_gen'
import { usePaygroupFilter } from './PaygroupFilter'
import { EmployeeRow } from './types'

const StatusLabel: Record<EmployeeAccountStatus, string> = {
  [EmployeeAccountStatus.Enrolled]: 'Enrolled',
  [EmployeeAccountStatus.NotEnrolled]: 'Not enrolled',
  [EmployeeAccountStatus.Ineligible]: 'Ineligible',
  [EmployeeAccountStatus.Suspended]: 'Suspended',
  [EmployeeAccountStatus.Terminated]: 'Terminated',
  [EmployeeAccountStatus.OptedOut]: 'Opted out',
}
const StatusColor: Record<EmployeeAccountStatus, ChipProps['color']> = {
  [EmployeeAccountStatus.Enrolled]: 'primary',
  // [EmployeeAccountStatus.NotEnrolled]: 'info',
  [EmployeeAccountStatus.NotEnrolled]: 'error',
  [EmployeeAccountStatus.Ineligible]: 'info',
  [EmployeeAccountStatus.Suspended]: 'info',
  [EmployeeAccountStatus.Terminated]: 'info',
  [EmployeeAccountStatus.OptedOut]: 'info',
}
const StatusChip = ({ status }: { status: EmployeeAccountStatus }) => {
  return <Chip label={StatusLabel[status]} color={StatusColor[status]} size="small" />
}

const RoleLabel: Record<EmployeeRole, string> = {
  [EmployeeRole.NonAdmin]: 'Non-admin',
  [EmployeeRole.EmployerAdmin]: 'Admin',
  [EmployeeRole.OrganizationAdmin]: 'Admin',
}

const RoleColor: Record<EmployeeRole, ChipProps['color']> = {
  [EmployeeRole.NonAdmin]: 'default',
  [EmployeeRole.EmployerAdmin]: 'secondary',
  [EmployeeRole.OrganizationAdmin]: 'primary',
}

const RoleChip = ({ role }: { role: EmployeeRole }) => {
  if (role === EmployeeRole.NonAdmin) return null
  return <Chip label={RoleLabel[role]} color={RoleColor[role]} size="small" />
}

const columns: GridColDef<EmployeeRow>[] = [
  { field: 'id', headerName: 'ID', sortable: false, filterable: false, width: 70 },
  {
    field: 'firstName',
    headerName: 'First',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<EmployeeRow, EmployeeRole>) => <PII>{params.value}</PII>,
  },
  {
    field: 'lastName',
    headerName: 'Last',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<EmployeeRow, EmployeeRole>) => <PII>{params.value}</PII>,
  },
  {
    field: 'paygroup',
    headerName: 'Pay group',
    sortable: false,
    filterable: false,
    flex: 2,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: false,
    filterable: false,
    flex: 2,
    renderCell: (params: GridRenderCellParams<EmployeeRow, EmployeeRole>) => <PII>{params.value}</PII>,
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    type: 'date',
    sortable: false,
    filterable: false,
    width: 110,
  },
  {
    field: 'role',
    headerName: 'Role',
    type: 'singleSelect',
    valueOptions: Object.values(EmployeeRole),
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<EmployeeRow, EmployeeRole>) =>
      params.value && <RoleChip role={params.value} />,
    width: 150,
  },
  {
    field: 'accountStatus',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: Object.values(EmployeeAccountStatus),
    sortable: false,
    renderCell: (params: GridRenderCellParams<EmployeeRow, EmployeeAccountStatus>) =>
      params.value && <StatusChip status={params.value} />,
    filterable: false,
    width: 150,
  },
]

const employeeRow = (employee: EmployeeInfoFragment): EmployeeRow => {
  return {
    id: employee.id,
    firstName: employee.user.firstName || '',
    lastName: employee.user.lastName || '',
    paygroup: employee.paygroupInfo.name || '',
    email: employee.user.email || '',
    accountStatus: employee.enrollmentInfo?.accountStatus,
    role: employee.role,
    startDate: employee.employmentStartDate
      ? dateStringFormat.dateFromDateString(employee.employmentStartDate)
      : null,
  }
}

const usePaginationModelEmployeeList = (pageSize: number) => {
  const [paginationModel, setPaginationModel] = React.useState({ pageSize, page: 0 })
  const [paging, setPaging] = usePageSizePagingContext(pageSize)
  const resetPagination = () => {
    setPaginationModel({ pageSize, page: 0 })
    setPaging({ first: pageSize })
  }
  const [searchValues, handleFilterChangeSearch] = useSearchFilter()
  const [accountStatusValues] = useAccountStatusFilter()
  const [paygroupId] = usePaygroupFilter()
  React.useEffect(() => {
    resetPagination()
    // Because resetPagination is a reset, we can treat it as a constant
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStatusValues, paygroupId])
  const { data: employeeListData, isLoading } = useOrgEmployeeListQuery({
    ...paging,
    searchTerms: searchValues,
    accountStatuses: accountStatusValues,
    employerId: paygroupId ? parseInt(paygroupId) : undefined,
  })
  const employeeConnection = employeeListData?.organization?.employees
  const totalCount = employeeConnection?.totalCount
  const employees = employeeConnection?.nodes?.filter(isNotNull)
  const pageInfo = employeeConnection?.pageInfo
  const rows = (employees || []).map(employeeRow)

  const rowCountRef = React.useRef(totalCount || 0)

  const rowCount = React.useMemo(() => {
    if (totalCount !== undefined) {
      rowCountRef.current = totalCount
    }
    return rowCountRef.current
  }, [totalCount])

  // Keep track of paging history to support back navigation using same `first/after` paging params.
  // Could use `last/before` params, but this enables better caching
  const pagingHistory = React.useRef<Array<PageSizePagingState>>([{}])
  const paginationMeta = { hasNextPage: employeeConnection?.pageInfo?.hasNextPage }

  const onPaginationModelChange = (model: { pageSize: number; page: number }) => {
    if (!pageInfo || model.page === paginationModel.page) return
    if (model.page > paginationModel.page) {
      pagingHistory.current.push(paging)
      setPaging({ first: pageSize, after: pageInfo.endCursor })
    } else {
      const newPaging = pagingHistory.current.pop() || {}
      setPaging(newPaging)
    }
    setPaginationModel(model)
  }
  const onFilterModelChange = (filterModel: GridFilterModel) => {
    resetPagination()
    handleFilterChangeSearch(filterModel)
  }

  return {
    rows,
    loading: isLoading,
    paginationMeta,
    paginationModel,
    onPaginationModelChange,
    rowCount,
    onFilterModelChange,
  }
}

const PagedEmployeesList = ({ pageSize = 40 }) => {
  const [quickFilterValues] = useSearchFilter()
  const dataProps = usePaginationModelEmployeeList(pageSize)
  const { data: employeeData } = useEmployeeQuery()
  const currentUserEmployee = employeeData?.employee
  return (
    <>
      <DataGrid
        autoHeight
        density="compact"
        paginationMode="server"
        filterMode="server"
        {...dataProps}
        columns={columns}
        pageSizeOptions={[pageSize]}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        isRowSelectable={(params: GridRowParams<EmployeeRow>) =>
          params.row.id !== currentUserEmployee?.id &&
          params.row.accountStatus !== EmployeeAccountStatus.Terminated
        }
        slots={{ toolbar: EmployeeFilter }}
        initialState={{
          filter: {
            filterModel: {
              quickFilterValues,
              items: [],
            },
          },
        }}
        checkboxSelection
      />
    </>
  )
}

export const EmployeeList = () => {
  return (
    <Box sx={{ minHeight: 100, width: '80vw' }}>
      <PageSizePagingContextProvider>
        <PagedEmployeesList />
      </PageSizePagingContextProvider>
    </Box>
  )
}
