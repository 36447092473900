import {
  Button,
  Container,
  FullDialog,
  Grid,
  ListItemText,
  Typography,
  useInfoDrawerControl,
} from '@alice-financial/pretext-ui'
import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import { BillingSourceFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { ConnectBillingSource } from './ConnectBillingSource'
import { billingSourceDescriptor, isExpiredCard, hasBillingSourceError, isOrg } from './billingUtils'
import { Billable } from './types'

type ConnectBillingButtonProps = {
  billable: Billable
}
const ConnectBillingButton = ({ billable, ...buttonProps }: ConnectBillingButtonProps) => {
  const currentBillingSource = billable.billing?.billingSource
  const { onOpen, onClose, open } = useInfoDrawerControl(
    `${billable.__typename}-${billable.id}-connect-billing`
  )
  const isActive = !isExpiredCard(currentBillingSource)
  return (
    <>
      <Button
        variant={currentBillingSource && isActive ? 'outlined' : 'contained'}
        color={!currentBillingSource || isActive ? 'primary' : 'error'}
        size="small"
        endIcon={!currentBillingSource && <AddIcon />}
        sx={{ textWrap: 'nowrap' }}
        onClick={onOpen}
        disabled={open}
        {...buttonProps}
      >
        {currentBillingSource ? 'Update' : 'Connect account'}
      </Button>
      <FullDialog
        open={open}
        onClose={onClose}
        title={<Typography variant="h2">Connect billing account</Typography>}
      >
        {currentBillingSource && (
          <Container>
            <Typography variant="body2" gutterBottom>
              Your current funding account is <strong>{billingSourceDescriptor(currentBillingSource)}</strong>
              .
            </Typography>
          </Container>
        )}
        <ConnectBillingSource
          currentBillingSource={currentBillingSource}
          billable={billable}
          onSuccess={onClose}
        />
      </FullDialog>
    </>
  )
}

type BillingFormProps = {
  billingSource?: BillingSourceFragment | null | undefined
  billable: Billable
  readonly?: boolean
}

type BillingErrorProps = {
  billingSource: BillingSourceFragment
}

const BillingError = ({ billingSource }: BillingErrorProps) => {
  const errorMessage = isExpiredCard(billingSource)
    ? 'expired — update required'
    : billingSource.error?.message
  return (
    <Typography variant="caption" color="error" fontWeight="bold" sx={{ marginLeft: 1 }}>
      {errorMessage}
    </Typography>
  )
}

export const BillableBilling = ({ billable, readonly }: BillingFormProps) => {
  const currentBillingSource = billable.billing?.billingSource
  const hasError =
    currentBillingSource &&
    (isExpiredCard(currentBillingSource) || hasBillingSourceError(currentBillingSource))
  return (
    <>
      <ListItemText
        primary={
          <Typography variant="body2" color="primary" fontWeight="bold">
            {isOrg(billable) ? 'Billing account for all pay groups' : billable.name}
            {hasError && <BillingError billingSource={currentBillingSource} />}
          </Typography>
        }
        secondary={
          <Typography variant="caption" component="p" fontStyle={currentBillingSource ? undefined : 'italic'}>
            {currentBillingSource ? billingSourceDescriptor(currentBillingSource) : 'not connected'}
          </Typography>
        }
      />
      {!readonly && (
        <Grid
          container
          flexShrink={1}
          width="auto"
          columnGap={1}
          rowGap={1}
          flexDirection="row-reverse"
          flexWrap="nowrap"
        >
          <ConnectBillingButton billable={billable} />
        </Grid>
      )}
    </>
  )
}
