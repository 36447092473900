import {
  Callout,
  Collapse,
  Grid,
  InlineInputController,
  InputLabel,
  SelectController,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { getLastDayOfMonth } from '../../utils/dateUtils'
import { dateFormat, dateStringFormat } from '../../utils/formatters/dateFormat'
import {
  OrgOnboardingFormValues,
  PayrollFrequency,
  PayrollValues,
  TwiceMonthlyPaySchedule,
} from './onboarding/types'
import { useQueryPayrollPlatforms } from './onboarding/useQueryPayrollPlatform'
const PAYROLL_FREQUENCY_OPTIONS: Array<{ value: PayrollFrequency; label: string }> = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'fortnightly', label: 'Every 2 weeks' },
  { value: 'twice_per_month', label: 'Twice a month' },
  { value: 'monthly', label: 'Monthly' },
]
type PayrollInputProps<TValues extends PayrollValues> = UseFormReturn<TValues> & {
  disabled?: boolean
}
/**
 * TypeScript cannot correctly infer the signatures of UseFormReturn properties when passed in as props,
 * so we need to declare the supported form value types here - note that TypeScript will still show an
 * error if you attempt to add a set of form values that _do not_ extend `PayrollValues` as required.
 */
type SupportedFormValues = OrgOnboardingFormValues

/**
 * This component renders all payroll-related inputs for organizations
 * - num_locations (in payroll)
 * - payroll_platform_id
 * - pay_schedule-related fields (see :payroll_schedule_params in the REST API)
 */
export const PayrollInput = ({
  disabled,
  control,
  watch,
  setValue,
}: PayrollInputProps<SupportedFormValues>) => {
  const { data: payrollPlatformData } = useQueryPayrollPlatforms()
  const payrollPlatforms = payrollPlatformData || []
  const [
    payrollFrequency,
    twiceMonthlyType,
    twiceMonthlyPayday1,
    twiceMonthlyPayday2,
    nextPayday,
    lastDayOfPayPeriod,
  ] = watch([
    'payroll_frequency',
    'twice_monthly_type',
    'twice_monthly_payday_1',
    'twice_monthly_payday_2',
    'next_payday',
    'last_day_of_pay_period',
  ])
  const twiceMonthlyPayday1_int = parseInt(twiceMonthlyPayday1, 10)
  const twiceMonthlyPayday2_int = parseInt(twiceMonthlyPayday2, 10)

  React.useEffect(() => {
    if (payrollFrequency !== 'twice_per_month') return
    if (twiceMonthlyType === 'fifteenth_and_last') {
      if (twiceMonthlyPayday1_int !== 15) setValue('twice_monthly_payday_1', '15')
      if (twiceMonthlyPayday2 !== 'last_day_of_month') setValue('twice_monthly_payday_2', 'last_day_of_month')
      return
    }
    if (twiceMonthlyType === 'other') {
      if (
        twiceMonthlyPayday1 &&
        twiceMonthlyPayday2 !== 'last_day_of_month' &&
        twiceMonthlyPayday2_int <= twiceMonthlyPayday1_int
      ) {
        setValue('twice_monthly_payday_1', (twiceMonthlyPayday2_int + 1).toString())
      }
    }
  }, [
    payrollFrequency,
    twiceMonthlyType,
    twiceMonthlyPayday1,
    twiceMonthlyPayday2,
    setValue,
    twiceMonthlyPayday1_int,
    twiceMonthlyPayday2_int,
  ])

  // first payday can be any day of the month up to the second monthly payday 2
  const validTwiceMonthlyPayday1s: Array<TwiceMonthlyPaySchedule['twice_monthly_payday_1']> = Array.from(
    {
      length:
        (twiceMonthlyPayday2 && twiceMonthlyPayday2 !== 'last_day_of_month' && twiceMonthlyPayday2_int - 1) ||
        30,
    },
    (_, i) => (i + 1).toString()
  )
  // second payday can be any day of the month after the first payday, not including the 31st, which will be 'last_day_of_month'
  const validTwiceMonthlyPayday2s: Array<TwiceMonthlyPaySchedule['twice_monthly_payday_2']> = Array.from(
    { length: 30 - twiceMonthlyPayday1_int },
    (_, i) => (i + 1 + twiceMonthlyPayday1_int).toString()
  )

  return (
    <Grid container spacing={1} rowGap={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Payroll information</Typography>
        <Typography variant="body2">
          Let us know how you run payroll and we&apos;ll be able to provide more detailed payroll provider
          setup information when you&apos;re ready for the next step.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InputLabel sx={{ display: 'block', marginBottom: 2 }}>
          Payroll provider{' '}
          <SelectController disabled={disabled} name="payroll_platform_id" control={control}>
            <option value=""></option>
            {payrollPlatforms.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </SelectController>
        </InputLabel>
        <InputLabel sx={{ display: 'block', marginBottom: 2 }}>
          Number of pay groups in payroll{' '}
          <InlineInputController
            control={control}
            name="num_locations"
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            type="number"
          />
        </InputLabel>
        <InputLabel sx={{ display: 'block', marginBottom: 2 }}>
          Payroll frequency{' '}
          <SelectController disabled={disabled} name="payroll_frequency" control={control}>
            {PAYROLL_FREQUENCY_OPTIONS.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </SelectController>
        </InputLabel>
        <Collapse in={payrollFrequency === 'twice_per_month'} unmountOnExit>
          <InputLabel sx={{ display: 'block', marginBottom: 2 }}>
            Monthly paydays{' '}
            <SelectController disabled={disabled} name="twice_monthly_type" control={control}>
              <option value="fifteenth_and_last">15th and last day of month</option>
              <option value="other">Other</option>
            </SelectController>
          </InputLabel>
          <Collapse in={twiceMonthlyType === 'other'} unmountOnExit>
            <Callout sx={{ marginBottom: 2 }}>
              <InputLabel sx={{ marginBottom: 2 }}>
                First payday of the month{' '}
                <SelectController disabled={disabled} name="twice_monthly_payday_1" control={control}>
                  {validTwiceMonthlyPayday1s.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </SelectController>
              </InputLabel>
              <InputLabel>
                Second payday of the month{' '}
                <SelectController disabled={disabled} name="twice_monthly_payday_2" control={control}>
                  {validTwiceMonthlyPayday2s.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                  <option value="last_day_of_month">Last day of month</option>
                </SelectController>
              </InputLabel>
            </Callout>
          </Collapse>
        </Collapse>

        <InputLabel sx={{ marginBottom: 2 }}>
          Next pay date{' '}
          <InlineInputController
            name="next_payday"
            disabled={disabled}
            inputProps={{ min: lastDayOfPayPeriod || dateFormat.inputVal(new Date()) }}
            control={control}
            rules={{
              validate: (value) => {
                // if payrollFrequency is twice per month, last day of pay period _must_ be one of the pay days
                if (!value || payrollFrequency !== 'twice_per_month') return true
                const selectedDate = dateStringFormat.dateFromDateString(value)
                const dayOfMonth = selectedDate.getDate()
                const lastDayOfMonth = getLastDayOfMonth(selectedDate)
                if (dayOfMonth === twiceMonthlyPayday1_int) return true
                if (dayOfMonth === twiceMonthlyPayday2_int) return true
                if (twiceMonthlyPayday2 === 'last_day_of_month' && dayOfMonth === lastDayOfMonth) return true

                return 'Next pay date must be one of the selected monthly paydays'
              },
            }}
            type="date"
          />
          <Typography variant="caption" whiteSpace="normal" display="block" color="text.primary">
            If the next regular pay day falls on a holiday or weekend, enter that date, not the actual pay
            day.
          </Typography>
        </InputLabel>
        <InputLabel sx={{ marginBottom: 2 }}>
          Last day of pay period{' '}
          <InlineInputController
            name="last_day_of_pay_period"
            disabled={disabled}
            inputProps={{ max: nextPayday }}
            control={control}
            type="date"
            rules={{
              validate: (value) => {
                if (value && nextPayday && value > nextPayday) {
                  return 'Last day of pay period must be before next pay day'
                }
              },
            }}
          />
          <Typography variant="caption" whiteSpace="normal" display="block" color="text.primary">
            This is the last date of your company&apos;s first pay period with Alice. For work done during the
            pay period ending on this date, your employees will be paid on the above pay date.
          </Typography>
        </InputLabel>
        <InputLabel sx={{ display: 'block', marginBottom: 2 }}>
          Business days before payday
          <InlineInputController
            control={control}
            name="days_before_payday_submitted"
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            type="number"
          />
          <Typography variant="caption" whiteSpace="normal" display="block" color="text.primary">
            Business days before payday that payroll must be submitted
          </Typography>
        </InputLabel>
      </Grid>
    </Grid>
  )
}
