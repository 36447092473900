import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { ConfirmBankAccountSetupResponse } from '../../../externalServices/stripe/useConfirmBankAccount'
import { useOrgDashboardQuery } from '../../homepage/gql/orgDashboard_gen'
import {
  CreateAliceCardPaymentMethodMutation,
  useCreateAliceCardPaymentMethodMutation,
} from './gql/createPaymentMethod_gen'
import { useStripeSetupIntentSecretQuery } from './gql/stripeSetupIntentSecret_gen'
import { TypedBankableEntity } from './types'
import { CreatePaymentMethodInput } from '../../../graphql/generated.types'

type UseCreateAliceCardPaymentMethodMutationOptions = UseGqlMutationCallbacks<
  CreateAliceCardPaymentMethodMutation,
  ConfirmBankAccountSetupResponse
>

export const useCreateAliceCardPaymentMethod = (
  entity: TypedBankableEntity,
  bankInfo: Pick<CreatePaymentMethodInput, 'routingNumber' | 'accountNumber'>,
  mutationOptions?: UseCreateAliceCardPaymentMethodMutationOptions
) => {
  const { mutateAsync: createPaymentMethod } = useCreateAliceCardPaymentMethodMutation()

  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(mutationOptions, [
    useOrgDashboardQuery.getKey(),
    useStripeSetupIntentSecretQuery.getKey(),
  ])

  return useMutation(
    ['createAliceCardPayment'],
    ({ paymentMethodId }) =>
      createPaymentMethod({
        input: {
          paymentMethodId,
          accountNumber: bankInfo.accountNumber,
          routingNumber: bankInfo.routingNumber,
          ownerId: entity.id,
          ownerType: entity.type,
        },
      }),
    mutationOptionsWithInvalidation
  )
}
