import { Divider, PageBody, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'
import { WorkLocationList } from './WorkLocationList'
import { OrganizationLogoUploadForm } from '../organization/organizationLogoUploadForm'

export const WorkLocationsPage = () => {
  const { data: orgData } = useOrgDashboardQuery()
  const org = orgData?.organization
  if (!org) return null
  return (
    <PageBody maxWidth={800}>
      <Typography variant="h1" gutterBottom>
        Locations and Logos
      </Typography>
      <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
      <Typography variant="h2" gutterBottom>
        Organization
      </Typography>
      <Typography variant="body2" gutterBottom>
        Update the logo of your organization
      </Typography>
      <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
      <OrganizationLogoUploadForm org={org} />
      <Divider sx={{ marginTop: 4, marginBottom: 3 }} />
      <Typography variant="h2" gutterBottom>
        Locations
      </Typography>
      <Typography variant="body2" gutterBottom>
        Manage your company&apos;s locations and logos
      </Typography>
      {org && <WorkLocationList org={org} />}
    </PageBody>
  )
}
