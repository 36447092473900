import {
  composeMutationCallbacks,
  fetchRest,
  useMutationWithInvalidation,
  UseRestMutationCallbacks,
} from '@alice-financial/api'
import { useForm } from 'react-hook-form'
import { getServerErrorHandler } from '../../../utils/forms/ServerError'

export type ReenrollValues = {
  password: string
}

const setReenroll = async (params: ReenrollValues) =>
  fetchRest<undefined>('/api/user/reenroll', {
    method: 'PATCH',
    body: JSON.stringify(params),
  })

const _useReenroll = (mutationOptions?: UseRestMutationCallbacks<undefined, ReenrollValues>) =>
  useMutationWithInvalidation(setReenroll, mutationOptions, 'all')

// When a user manually re-enrolls (i.e. they are not automatically re-enrolled
// because they are coming back after the time window for an automatic re-enrollment),
// we're asking them to reset their password because we assume it's likely they've
// forgotten it. We don't need to ask for anything else given we already have their
// info from their previous enrollment.
export const useReenroll = (mutationOptions?: UseRestMutationCallbacks<undefined, ReenrollValues>) => {
  const { handleSubmit, ...form } = useForm<ReenrollValues>({
    defaultValues: {
      password: '',
    },
  })
  const onError = getServerErrorHandler(form.setError, ['password'])
  const { mutate: reenroll, ...mutationState } = _useReenroll(
    composeMutationCallbacks(mutationOptions, { onError })
  )

  return {
    onSubmit: handleSubmit((values) => reenroll(values)),
    ...form,
    ...mutationState,
  }
}
