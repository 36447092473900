import {
  ActionLayout,
  Alert,
  Box,
  Button,
  Container,
  FormHelperText,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemLink,
  ListItemText,
  Paper,
  Skeleton,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@alice-financial/pretext-ui'

import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import SearchIcon from '@mui/icons-material/Search'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEmployeeEnrollmentInfoQuery } from '../../../enrollment/gql/employeeEnrollmentInfo_gen'
import { ReenrollingWithoutSpendingConnectionsGreeting } from '../../../enrollment/reenrollment/ReenrollingWithoutSpendingConnectionsGreeting'
import {
  useEnrolledToday,
  useEnrollmentStatus,
  useIsEnrolling,
} from '../../../enrollment/useEnrollmentStatus'
import {
  BankingConnectionPlatform,
  EmployeeEnrollmentRequirementStatus,
  PlaidLinkSessionStatusEnum,
} from '../../../graphql/generated.types'
import { useOffboardDate } from '../../../organization/offboarding/orgOffboardUtils'
import { PageBody } from '../../../routes/PageBody'
import { EnrollmentRequirementsInfo } from '../EnrollmentRequirementsInfo'
import { LinkSessionFragment } from '../gql/connectedCardsQuery_gen'
import { useSearchBankingInstitutionsQuery } from '../gql/searchBankingInstitutions_gen'
import {
  useAliceCardFeatureEnabled,
  useHasAliceCards,
  useQuerySpendingConnections,
} from '../useQuerySpendingConnections'
import bankLogosUrl from './assets/bank-logos.png'
import { BankingConnectionList } from './BankingConnectionList'
import { getIsConnectedToInstitution, isAvailable } from './connect/bankingConnectionUtils'
import { useNavigateToPlaidLink } from './connect/usePlaidLink'
import { HowAliceWorksInfo } from '../../info/HowAliceWorksInfo'

type FinicityInstitution = {
  id: number
  name: string
  logoUrl?: string | null
}

const FinicityItem = ({ id, name, logoUrl }: FinicityInstitution) => {
  const {
    data: { bankingConnections },
  } = useQuerySpendingConnections()
  const hasConnected = bankingConnections.some(getIsConnectedToInstitution(id))
  if (hasConnected) return null
  const icon = logoUrl ? (
    <img src={logoUrl} width={42} />
  ) : (
    <AccountBalanceIcon fontSize="large" color="secondary" width={42} height={42} />
  )
  return (
    <ListItem divider disableGutters disablePadding>
      <ListItemLink to={`${id}/connect/finicity`}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItemLink>
    </ListItem>
  )
}

const PlatformConnect = styled(Paper)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(2),
  height: '100%',
}))

export const PERSONAL_CARDS_ROUTE = '/cards/personal'

const HowSpendingConnectionsWork = () => (
  <>
    <ul>
      <Typography component="li" gutterBottom>
        <FormattedMessage id="connections.scan_for_eligible" />
      </Typography>
      <Typography component="li" gutterBottom>
        <FormattedMessage id="connections.we_find_we_text" />
      </Typography>
    </ul>
    <Typography gutterBottom>
      <HowAliceWorksInfo>
        <FormattedMessage id="common.how_alice_works" />
      </HowAliceWorksInfo>
    </Typography>
  </>
)

const BankingConnectionsIntro = () => (
  <Typography variant="h2" gutterBottom>
    <FormattedMessage id="cards.personal.my_connected_bank_accounts" />
  </Typography>
)

type HasRecentLinkSessionIntroProps = {
  linkSession: LinkSessionFragment
}
const HasRecentLinkSessionIntro = ({ linkSession }: HasRecentLinkSessionIntroProps) => (
  <>
    <Typography variant="h2" gutterBottom>
      <FormattedMessage id="cards.personal.my_connected_bank_accounts" />
    </Typography>
    {linkSession.status === PlaidLinkSessionStatusEnum.Created && (
      <Alert severity="info">
        <FormattedMessage id="plaid.link_session.status.created" />
      </Alert>
    )}
    {linkSession.status === PlaidLinkSessionStatusEnum.Failed && (
      <Alert severity="info">
        <FormattedMessage id="plaid.link_session.status.created" />
      </Alert>
    )}
  </>
)

/**
 * This screen presents the user with their currently-connected bank accounts/cards and
 * allows them to connect new banks/cards so that we can watch their transactions for
 * eligible expenses.
 *
 */
export const BankingConnections = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const offboardDate = useOffboardDate()
  const { data: employeeEnrollmentData } = useEmployeeEnrollmentInfoQuery()
  const spendingConnectionComplete =
    employeeEnrollmentData?.employee?.enrollmentInfo.requirements.spendingConnection ===
    EmployeeEnrollmentRequirementStatus.Complete
  const isEnrolling = useIsEnrolling()
  const enrolledToday = useEnrolledToday()
  const completeLink = enrolledToday ? '/enroll/summary' : '/'
  const aliceCardEnabled = useAliceCardFeatureEnabled()
  const enrollmentStatus = useEnrollmentStatus()
  const navigateToPlaidLink = useNavigateToPlaidLink()
  const hasAliceCards = useHasAliceCards()

  const {
    data: { bankingConnections, recentLinkSession },
    isLoading,
  } = useQuerySpendingConnections()

  const { data: finicityInstitutionsData } = useSearchBankingInstitutionsQuery({
    preferredPlatform: BankingConnectionPlatform.Finicity,
  })
  const finicityInstitutions = finicityInstitutionsData?.searchBankingInstitutions || []
  if (offboardDate && offboardDate < new Date()) return null

  const hasAvailableCards = bankingConnections.filter(isAvailable).length > 0
  const needsReenrollment = enrollmentStatus === 'must_reenroll_via_spending_connection'
  const IntroMessaging = () => {
    if (enrollmentStatus === undefined) return null
    if (needsReenrollment) return <ReenrollingWithoutSpendingConnectionsGreeting />
    if (recentLinkSession && recentLinkSession.status !== PlaidLinkSessionStatusEnum.Completed) {
      return <HasRecentLinkSessionIntro linkSession={recentLinkSession} />
    }
    return <BankingConnectionsIntro />
  }

  const startPlaidLink = () => navigateToPlaidLink({})

  return (
    <PageBody>
      <Container>
        {isLoading ? <Skeleton /> : <IntroMessaging />}

        <HowSpendingConnectionsWork />
        <BankingConnectionList bankingConnections={bankingConnections} />

        {hasAvailableCards ? (
          <Typography variant="h2" gutterBottom sx={{ mt: 3 }}>
            <FormattedMessage id="connections.connect_another" />
          </Typography>
        ) : (
          <Typography gutterBottom>
            <FormattedMessage id="connections.select_from_list" />
          </Typography>
        )}
        <PlatformConnect>
          <List disablePadding>
            {finicityInstitutions.map(({ id, name, logoUrl }) => (
              <FinicityItem key={id} id={id} name={name} logoUrl={logoUrl} />
            ))}
            <ListItem divider disableGutters disablePadding>
              <ListItemButton onClick={startPlaidLink} data-testid="connect-all-other-banks">
                <ListItemIcon sx={{ alignSelf: 'flex-start' }}>
                  <SearchIcon color="primary" fontSize="large" />
                </ListItemIcon>
                <Box width="100%" mt="0.3em">
                  <ListItemText
                    primary={
                      <Typography fontWeight="bold" color="text.primary">
                        <FormattedMessage id="connections.all_other_banks" />
                      </Typography>
                    }
                    secondary={
                      <Typography variant="caption" component="p" gutterBottom>
                        <FormattedMessage id="connections.connect_through_plaid" />
                      </Typography>
                    }
                  />
                  <img width={200} src={bankLogosUrl} />
                </Box>
              </ListItemButton>
            </ListItem>
          </List>
        </PlatformConnect>

        {!spendingConnectionComplete &&
          aliceCardEnabled && ( // without Alice Card option, there's no ambiguity in what needs to be connected
            <FormHelperText sx={{ textAlign: isMobile ? 'center' : 'right' }} error>
              <FormattedMessage id="spending.connection.connect_a_spending_source_to_continue" />{' '}
              <EnrollmentRequirementsInfo>
                <FormattedMessage id="common.learn_more" />.
              </EnrollmentRequirementsInfo>
            </FormHelperText>
          )}
        <ActionLayout
          mt={0}
          primary={
            <Box width="100%">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!spendingConnectionComplete}
                component={Link}
                to={completeLink}
              >
                {isEnrolling && hasAliceCards && !hasAvailableCards ? (
                  <FormattedMessage id="common.skip" />
                ) : (
                  <FormattedMessage id="common.done" />
                )}
              </Button>
            </Box>
          }
          secondary={
            isEnrolling && aliceCardEnabled ? (
              <Button variant="outlined" color="secondary" component={Link} to="/cards/alice" fullWidth>
                <FormattedMessage id="connections.all_connections.alice_card.heading" />
              </Button>
            ) : null
          }
        />
        <Box mt={6}></Box>
      </Container>
    </PageBody>
  )
}
