import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { UpdateEmployeesInput } from '../../graphql/generated.types'
import { useOrgEmployeeListQuery } from './gql/orgEmployeeList_gen'
import { UpdateEmployeesMutation, useUpdateEmployeesMutation } from './gql/updateEmployees_gen'

type UseUpdateEmployeesMutationOptions = UseGqlMutationCallbacks<UpdateEmployeesMutation>
export const useUpdateEmployees = (
  employeeIds: Array<number>,
  mutationOptions?: UseUpdateEmployeesMutationOptions
) => {
  const optionsWithNotifs = useMutationNotifier({}, mutationOptions)
  const optionsWithInvalidation = useMutationOptionsWithInvalidation(optionsWithNotifs, [
    useOrgEmployeeListQuery.getKey(),
  ])
  const { mutateAsync } = useUpdateEmployeesMutation(optionsWithInvalidation)
  return useMutation(
    ['updateEmployees'],
    (attributes: Pick<UpdateEmployeesInput, 'accountStatus' | 'role'>) =>
      mutateAsync({ employeeIds, ...attributes })
  )
}
