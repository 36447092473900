import { useOrgDashboardQuery } from '../../orgDashboard/homepage/gql/orgDashboard_gen'
import { useCurrentUserOnboardingQuery } from './gql/currentUserOnboarding_gen'

type ErrorOnboardingStatus = ['error', undefined]
type UndefinedOnboardingStatus = [undefined, undefined]
type NoProvisionalOrgOnboardingStatus = ['no_user' | 'no_onboarding', undefined]
type CompletedOnboardingStatus = ['completed_onboarding_flow', undefined]
type ProvisionalOrgOnboardingStatus = ['unconverted_provisional_org', number | undefined]
type OnboardingStatusData =
  | ErrorOnboardingStatus
  | UndefinedOnboardingStatus
  | CompletedOnboardingStatus
  | NoProvisionalOrgOnboardingStatus
  | ProvisionalOrgOnboardingStatus

export const isCompletedOnboardingStatus = (
  status: OnboardingStatusData[0]
): status is CompletedOnboardingStatus[0] => Boolean(status === 'completed_onboarding_flow')

export const useOnboardingStatus = (): OnboardingStatusData => {
  const { data: currentUserOnboardingData, isError: isOnbardingQueryError } = useCurrentUserOnboardingQuery()
  const { data: orgData } = useOrgDashboardQuery()

  if (!currentUserOnboardingData) return [undefined, undefined]
  if (isOnbardingQueryError) return ['error', undefined]
  const currentUserOnboarding = currentUserOnboardingData?.currentUser
  if (!currentUserOnboarding) return ['no_user', undefined]
  // if the user already has an organization, they are definitionally done with the
  // start of the onboarding flow
  if (orgData?.organization) return ['completed_onboarding_flow', undefined]

  const { provisionalOrganizations } = currentUserOnboarding
  if (!provisionalOrganizations || provisionalOrganizations.length === 0) {
    return ['no_onboarding', undefined]
  }
  return ['unconverted_provisional_org', provisionalOrganizations[0]?.id]
}

export const useIsOnboarding = () => {
  const [status] = useOnboardingStatus()
  if (status === undefined) return undefined
  return status !== 'no_onboarding' && status !== 'completed_onboarding_flow'
}
