import * as React from 'react'
import { Navigate, RouteObject } from 'react-router'
import { FinchAuthGateway } from '../externalServices/finch/FinchAuthGateway'
import { AdminProfilePage } from './adminProfile/AdminProfilePage'
import { AliceCardProgramPage } from './aliceCardProgram/AliceCardProgramPage'
import { EnableConnectComponents } from './aliceCardProgram/EnableConnectComponents'
import { FundingAccountPage } from './aliceCardProgram/FundingAccountPage'
import { BillingPage } from './billing/BillingPage'
import { DocumentsPage } from './documents/DocumentsPage'
import { EmployerHomepage } from './employer/EmployerHomepage'
import { OrgHomepage } from './homepage/OrgHomepage'
import { OrgDashboardRouteChrome } from './OrgDashboardRouteChrome'
import { PayPeriodsPage } from './payPeriods/PayPeriodsPage'
import { PayrollReportsPage } from './payrollReports/PayrollReportsPage'
import { WorkLocationsPage } from './workLocations/WorkLocationsPage'
import { EmployeeListPage } from './employeeList/EmployeeListPage'

/**
 * These are the employee dashboard routes that should be mounted at the root of
 * the application.
 */
export const orgDashboardRoutes: RouteObject = {
  children: [
    {
      path: 'manage',
      element: <OrgDashboardRouteChrome />,
      children: [
        { index: true, element: <OrgHomepage /> },
        { path: 'account', element: <Navigate to="/manage/billing" replace /> },
        { path: 'accounts', element: <Navigate to="/manage/billing" replace /> },
        { path: 'activities', element: <Navigate to="/manage/pay-periods" replace /> },
        {
          path: 'alice-card-program',
          Component: EnableConnectComponents,
          children: [
            { index: true, element: <AliceCardProgramPage /> },
            { path: 'account', element: <FundingAccountPage /> },
          ],
        },
        { path: 'billing', element: <BillingPage /> },
        { path: 'documents', element: <DocumentsPage /> },
        { path: 'invoices', element: <Navigate to="/manage/billing" replace /> },
        { path: 'locations', element: <WorkLocationsPage /> },
        { path: 'pay-periods', element: <PayPeriodsPage /> },
        {
          path: 'people',
          element: <EmployeeListPage />,
        },
        { path: 'profile', element: <AdminProfilePage /> },
        { path: 'reports', element: <PayrollReportsPage /> },
        {
          path: ':employerId',
          children: [
            { index: true, element: <EmployerHomepage /> },
            { path: 'account', element: <Navigate to="../billing" replace /> },
            { path: 'accounts', element: <Navigate to="../billing" replace /> },
            { path: 'activities', element: <Navigate to="../pay-periods" replace /> },
            { path: 'billing', element: <BillingPage /> },
            { path: 'documents', element: <DocumentsPage /> },
            {
              path: 'finch-auth-gateway',
              index: true,
              element: <FinchAuthGateway />,
            },
            { path: 'pay-periods', element: <PayPeriodsPage /> },
            { path: 'people', element: <EmployeeListPage /> },
          ],
        },
        {
          path: 'beta',
          children: [],
        },
      ],
    },
  ],
}
