import { fetchRest } from '@alice-financial/api'
import { useQuery } from '@tanstack/react-query'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { StatusNotification, Typography } from '@alice-financial/pretext-ui'
import { FormattedMessage } from 'react-intl'

type FetchDocumentUrlResponse = { url: string }
const useDocRemoteLinkQuery = (docId: string | undefined) =>
  useQuery(
    ['docs', docId],
    (): Promise<string> =>
      fetchRest<FetchDocumentUrlResponse>(`/api/user/documents/${docId}/remote_link`).then(({ url }) => url)
  )

/**
 * Gathers the URL of a doc (usually stored in AWS) and then redirects to it.
 */
export const FetchRemoteDocRoute = () => {
  const { docId } = useParams()
  const { data: docUrl } = useDocRemoteLinkQuery(docId)

  React.useEffect(() => {
    if (!docUrl) return
    window.location.replace(docUrl)
  }, [docUrl])

  return (
    <StatusNotification open={!!docUrl} variant="pending">
      <Typography variant="body2" fontWeight="bold">
        <FormattedMessage id="common.loading" />
      </Typography>
    </StatusNotification>
  )
}
