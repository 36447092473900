import { HealthcheckItem, InertHealthcheckItem, InertHealthcheckItemProps } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useLocation } from 'react-router'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { getBillingProblem } from './billingUtils'

const ItemDisplay = (props: InertHealthcheckItemProps) => {
  const location = useLocation()
  if (location.pathname === '/manage/accounts') {
    return <InertHealthcheckItem {...props} />
  }
  return <HealthcheckItem {...props} to="/manage/accounts" />
}

type BillingHealthcheckProps = {
  org?: OrganizationDetailFragment | null
}

export const BillingHealthcheck = ({ org }: BillingHealthcheckProps) => {
  if (!org) return null

  const billingProblem = getBillingProblem(org)
  if (!billingProblem) {
    return (
      <ItemDisplay
        status="complete"
        primary="Billing connected"
        secondary={org.billing?.hasUnifiedBilling ? 'Payment method is active' : 'Payment methods are active'}
      />
    )
  }
  if (billingProblem === 'not_started') {
    return (
      <ItemDisplay
        status="incomplete"
        primary="Billing setup incomplete"
        secondary="Configure and connect billing methods"
      />
    )
  }
  if (billingProblem[0] === 'missing_source') {
    return (
      <ItemDisplay
        status="incomplete"
        primary="Billing setup incomplete"
        secondary="Connect billing methods for remaining pay groups"
      />
    )
  }
  if (billingProblem[0] === 'expired') {
    return (
      <ItemDisplay
        status="incomplete"
        primary="Billing card expired"
        secondary="Update expired payment card(s)"
      />
    )
  }
  return null // shouldn't ever get here
}
