import * as React from 'react'
import { LanguagePreference } from '../app/graphql/generated.types'
import { useTrackingContext } from '../app/telemetry/TrackingProvider'
import { useCurrentUserQuery } from '../app/user/gql/currentUser_gen'
import { usePatchUserLanguagePref } from '../app/user/usePatchUserLanguge'
import { SupportedLangCode } from './types'

const SUPPORTED_LANGS: Array<SupportedLangCode> = ['en-us', 'es']
const DEFAULT_LANGUAGE: SupportedLangCode = 'en-us'
const LOCAL_LANGUAGE_KEY = '_alice_locale'

export const supportedLangToLanguagePref = (langCode: SupportedLangCode): LanguagePreference => {
  switch (langCode) {
    case 'en-us':
      return LanguagePreference.EnUs
    case 'es':
      return LanguagePreference.Es
    default:
      return LanguagePreference.EnUs
  }
}

const formatAsSupportedLangCode = (langCode: string): SupportedLangCode => {
  if (langCode.startsWith('en')) return 'en-us' // all 'English' will be served US English
  if (langCode.startsWith('es')) return 'es' // all 'Spanish' will be served our general Spanish
  return 'en-us' // default to English
} // read querystring when app first loads to check for language preference from inbound link

export const isSupportedLang = (langCode: unknown): langCode is SupportedLangCode =>
  SUPPORTED_LANGS.includes(langCode as SupportedLangCode)

const getLocalStorageLanguage = () => {
  const localStorageLang = window.localStorage.getItem(LOCAL_LANGUAGE_KEY)
  return isSupportedLang(localStorageLang) ? localStorageLang : null
}
const setLocalStorageLanguage = (langCode: SupportedLangCode) => {
  window.localStorage.setItem(LOCAL_LANGUAGE_KEY, langCode)
}

/**
 * 'getter' for current language preference from environment.
 * 1. Language code specified in current querystring
 * 2. Any local storage setting that exists from a previous session
 * 3. Browser's language preference
 * 4. Default: 'en-us'
 */
const getInitialLanguage = () => {
  const initialSearchParams = new URLSearchParams(window.location.search)
  const initialQuerystringLanguage = initialSearchParams.get('language')
  const localStorageLanguage = getLocalStorageLanguage()
  const browserLanguage = formatAsSupportedLangCode(navigator.language)
  return (
    [initialQuerystringLanguage, localStorageLanguage, browserLanguage].find(isSupportedLang) ||
    DEFAULT_LANGUAGE
  )
}

type LanguageState = readonly [SupportedLangCode, (langCode: SupportedLangCode) => void, boolean]
/**
 * This hook keeps language preference in sync between the front end and back end.
 *
 * The back end user language is always the source of truth, and this hook exposes a method of changing
 * it, with optimistic updates.
 */
const useLanguageState = (): LanguageState => {
  const { data: currentUserData } = useCurrentUserQuery()
  const userLanguage =
    currentUserData?.currentUser?.languagePreference &&
    formatAsSupportedLangCode(currentUserData?.currentUser?.languagePreference)

  const { mutate: saveUserLang, isLoading: isSavingUserLang } = usePatchUserLanguagePref()
  const [globalTrackingContext, setTrackingProperty] = useTrackingContext()

  // This is the state of the UI's displayed language - it
  const [appLanguage, setAppLanguage] = React.useState<SupportedLangCode>(
    userLanguage || getInitialLanguage()
  )

  // Propagate the language preference to the global context and local storage
  React.useEffect(() => {
    if (appLanguage !== getLocalStorageLanguage()) {
      setLocalStorageLanguage(appLanguage) // in future sessions, we can load the correct language immediately
    }
    if (globalTrackingContext.language !== appLanguage) {
      setTrackingProperty('language', appLanguage)
    }
  }, [appLanguage, globalTrackingContext.language, setTrackingProperty])

  // define a wrapper around _setLanguagePref that will prevent invalid calls and set the user's language preference
  // note that the user's language will _only_ be set if `setLanguagePref` is called - it will not be set from
  // the initial querystring or local storage language
  const setLanguagePref = React.useCallback(
    (newLanguagePref: SupportedLangCode | null) => {
      if (!newLanguagePref) return // don't allow null to be set
      if (!isSavingUserLang) setAppLanguage(newLanguagePref) // local state
      if (userLanguage && userLanguage !== newLanguagePref && !isSavingUserLang) {
        saveUserLang(newLanguagePref)
      }
    },
    [isSavingUserLang, saveUserLang, userLanguage]
  )

  // any time the user's preferred language in the API changes, update everything
  React.useEffect(() => {
    if (!isSavingUserLang && userLanguage && appLanguage !== userLanguage) setAppLanguage(userLanguage)
  }, [userLanguage, isSavingUserLang, appLanguage])

  return [appLanguage, setLanguagePref, isSavingUserLang]
}

const LocaleContext = React.createContext<LanguageState | null>(null)
type ProviderProps = {
  children?: React.ReactNode
}
/**
 * Provide [currentLang, setCurrentLang] state context for the application.
 */
export const LocaleProvider = ({ children }: ProviderProps) => {
  const langState = useLanguageState()

  return <LocaleContext.Provider value={langState}>{children}</LocaleContext.Provider>
}

/**
 * The hook that should be used to access the getter and setter for locale code/language
 */
export const useLocaleState = () => {
  const localeState = React.useContext(LocaleContext)
  if (!localeState) throw new Error('useLocaleState called outside of LocaleContext.Provider')
  return localeState
}
