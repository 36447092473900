import * as Types from '../../../../graphql/generated.types'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type StripeSetupIntentSecretQueryVariables = Types.Exact<{ [key: string]: never }>

export type StripeSetupIntentSecretQuery = {
  __typename?: 'Query'
  organization?: {
    __typename?: 'Organization'
    id: number
    aliceCardProgram: {
      __typename?: 'AliceCardProgram'
      companyAccount?: {
        __typename?: 'StripeCompanyAccount'
        id: number
        stripeAccountId?: string | null
        stripeSetupIntentSecret?: string | null
      } | null
    }
  } | null
}

export const StripeSetupIntentSecretDocument = `
    query StripeSetupIntentSecret {
  organization {
    id
    aliceCardProgram {
      companyAccount {
        id
        stripeAccountId
        stripeSetupIntentSecret
      }
    }
  }
}
    `

export const useStripeSetupIntentSecretQuery = <TData = StripeSetupIntentSecretQuery, TError = Error>(
  variables?: StripeSetupIntentSecretQueryVariables,
  options?: UseQueryOptions<StripeSetupIntentSecretQuery, TError, TData>
) => {
  return useQuery<StripeSetupIntentSecretQuery, TError, TData>(
    variables === undefined ? ['StripeSetupIntentSecret'] : ['StripeSetupIntentSecret', variables],
    fetchGql<StripeSetupIntentSecretQuery, StripeSetupIntentSecretQueryVariables>(
      StripeSetupIntentSecretDocument,
      variables
    ),
    options
  )
}

useStripeSetupIntentSecretQuery.getKey = (variables?: StripeSetupIntentSecretQueryVariables) =>
  variables === undefined ? ['StripeSetupIntentSecret'] : ['StripeSetupIntentSecret', variables]
