import { HeaderTitle, Link } from '@alice-financial/pretext-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { useParams } from 'react-router'
import { isNotNull } from '../../utils/typeUtils'
import { useOrgDashboardQuery } from './homepage/gql/orgDashboard_gen'

const TitleLink = ({ to, children }: { to: string; children: React.ReactNode }) => (
  <Link to={to} sx={{ textDecoration: 'none' }}>
    {children}
  </Link>
)

export const OrgRouteTitle = () => {
  const params = useParams()
  const { data: orgData } = useOrgDashboardQuery()
  if (!orgData) return null
  const employer = params.employerId
    ? orgData.organization?.employers.filter(isNotNull).find((e) => e.id.toString() === params.employerId)
    : null

  if (employer) {
    return (
      <HeaderTitle>
        <TitleLink to="/manage">{orgData.organization?.name}</TitleLink>{' '}
        <ChevronRightIcon sx={{ vericalAlign: 'bottom' }} />{' '}
        <TitleLink to={`/manage/${employer.id}`}>{employer.name}</TitleLink>
      </HeaderTitle>
    )
  }
  return (
    <HeaderTitle>
      <TitleLink to="/manage">{orgData.organization?.name}</TitleLink>
    </HeaderTitle>
  )
}
