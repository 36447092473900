import { UseRestMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useQueryClient } from '@tanstack/react-query'
import { EmployeeProfile, RestUser } from './types'
import { UserInput } from './types/UserInput'
import { USER_QUERY_KEY } from './useQueryUser'

/**
 * Return a user object that would result from the `userInput` being
 * successfully merged in
 */
const getOptimisticUser = (userInput: UserInput, user: RestUser): RestUser => {
  const employee_profile: EmployeeProfile = {
    ...user.employee_profile,
    employment_start_date: userInput.employment_start_date ?? user.employee_profile.employment_start_date,
  }
  // @TODO handle other parts of `userInput` other than address_attributes
  return {
    ...user,
    employee_profile,
  }
}

export const updateUser = (values: UserInput) =>
  fetchRest<RestUser>(`/api/user`, {
    method: 'PATCH',
    body: JSON.stringify(values),
  })

export const useUpdateUser = (
  mutationOptions?: UseRestMutationCallbacks<
    RestUser,
    UserInput,
    { previousUser: RestUser | undefined } | void
  >
) => {
  const queryClient = useQueryClient()
  return useMutationWithInvalidation(
    updateUser,
    {
      ...mutationOptions,
      onMutate: (values) => {
        mutationOptions?.onMutate && mutationOptions?.onMutate(values)
        // Snapshot the previous value from the user API object
        const previousUser = queryClient.getQueryData<RestUser>(USER_QUERY_KEY)

        if (!previousUser) return { previousUser: undefined }
        const optimisticUser = getOptimisticUser(values, previousUser)
        queryClient.setQueryData(USER_QUERY_KEY, () => optimisticUser)
        return { previousUser }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, newValues, context) => {
        mutationOptions?.onError && mutationOptions?.onError(err, newValues, context)
        if (context?.previousUser) {
          queryClient.setQueryData(USER_QUERY_KEY, context.previousUser)
        }
      },
      onSuccess: (data, newValues, context) => {
        queryClient.setQueryData(USER_QUERY_KEY, data)
        return mutationOptions?.onSuccess && mutationOptions?.onSuccess(data, newValues, context)
      },
    },
    'all'
  )
}
