import { UseRestMutationCallbacks, fetchRest, useMutationWithInvalidation } from '@alice-financial/api'
import { useConnectedCardsQuery } from '../../gql/connectedCardsQuery_gen'

const reportMissingCard = (cardId: number) => {
  const body = JSON.stringify({ alice_card_id: cardId })
  return fetchRest('/api/user/alice_card/report_missing', { method: 'POST', body })
}

type UseReportMissingCardMutationOptions = UseRestMutationCallbacks<unknown, number>
export const useReportMissingCard = (mutationOptions?: UseReportMissingCardMutationOptions) =>
  useMutationWithInvalidation(reportMissingCard, mutationOptions, [useConnectedCardsQuery.getKey()])
