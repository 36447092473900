import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Box,
  Button,
  ContainerProps,
  Grid,
  Paper,
  Theme,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import hexOutlineSvg from '../assets/hexagon-outline.svg'
import { Container } from '../layout'

const StepNumber = ({ stepNumber }: { stepNumber: number }) => {
  const isMobileWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const size = isMobileWidth ? '3.5rem' : '4.5rem'

  return (
    <Typography
      id={`step-number-${stepNumber}`}
      fontSize={`calc(${size} / 1.5)`}
      color="primary"
      fontFamily="sans-serif"
      fontWeight="bold"
      lineHeight={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        filter: 'drop-shadow(1px 2px 2px rgb(0 0 0 / 0.3))',
        position: 'absolute',
        width: size,
        height: size,
        top: `calc(${size} / -6)`,
        left: `calc(${size} / -1.2)`,
        background: `url(${hexOutlineSvg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {stepNumber}
    </Typography>
  )
}

type SectionProps = ContainerProps & {
  children: React.ReactNode
}
export const Section = ({ children, ...containerProps }: SectionProps) => {
  return (
    <Container sx={{ my: 3 }} component="section" {...containerProps}>
      <Box position="relative">{children}</Box>
    </Container>
  )
}

type WithLinkProps = {
  to: string
  toLabel?: string
}
type HeadingWithLinkProps = TypographyProps & WithLinkProps
type SectionHeadingProps = HeadingWithLinkProps | TypographyProps
const isHeadingWithLink = (props: SectionHeadingProps): props is HeadingWithLinkProps => 'to' in props

export const SectionHeading = (props: SectionHeadingProps) => {
  const { gutterBottom } = props
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={gutterBottom ? { mb: '0.5em' } : undefined}
    >
      <Grid item>
        <Typography variant="h2" {...props} gutterBottom={false} />
      </Grid>
      {isHeadingWithLink(props) && (
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            component={Link}
            to={props.to}
            endIcon={<ChevronRightIcon />}
          >
            {props.toLabel || <FormattedMessage id="common.see_more" />}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

type SectionCTAProps = {
  to: string
  children: React.ReactNode
  stepNumber?: number
}
export const SectionCTA = ({ to, children }: SectionCTAProps) => (
  <Box textAlign="right" mt={1} component={Container}>
    <Button variant="text" component={Link} to={to} endIcon={<ChevronRightIcon />}>
      {children}
    </Button>
  </Box>
)

type PaperSectionProps = SectionProps & {
  disablePadding?: boolean
  stepNumber?: number
}
export const PaperSection = ({ children, stepNumber, disablePadding }: PaperSectionProps) => {
  const isMobileWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const elevation = isMobileWidth ? 0 : undefined
  return (
    <Container sx={{ my: '1em', px: 0 }} component="section">
      <Paper
        elevation={elevation}
        sx={{ py: disablePadding ? 0 : '1.5em', pl: stepNumber ? '4rem' : undefined }}
      >
        <Box position="relative">
          {stepNumber && <StepNumber stepNumber={stepNumber} />}
          {children}
        </Box>
      </Paper>
    </Container>
  )
}
