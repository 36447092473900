import * as React from 'react'
import { useForm } from 'react-hook-form'
import { dateFormat, dateStringFormat } from '../../utils/formatters/dateFormat'
import { useUpdateUser } from '../user'
import { useEmployeeEnrollmentInfoQuery } from './gql/employeeEnrollmentInfo_gen'

export type CompleteEnrollmentValues = {
  employment_start_date: string
  already_started: boolean
}

export const useCompleteEnrollmentRequirements = () => {
  const { mutate: updateUser, ...mutationState } = useUpdateUser()
  const { data: enrollmentData } = useEmployeeEnrollmentInfoQuery()
  const employee = enrollmentData?.employee
  const knownStartDate = employee?.employmentStartDate
  const { handleSubmit, ...form } = useForm<CompleteEnrollmentValues>({
    values: {
      employment_start_date: knownStartDate || '',
      already_started: !knownStartDate || Boolean(new Date() > new Date(knownStartDate)),
    },
  })
  const [alreadyStarted, startDate] = form.watch(['already_started', 'employment_start_date'])
  const { setValue, getValues } = form

  // handle changes to 'already_started' independently of 'employment_start_date'
  React.useEffect(() => {
    // the start date input value is formatted as 'YYYY-MM-DD' - need to format it consistently
    // in order to compare with `today`
    const { employment_start_date } = getValues()
    const _startDate = employment_start_date && dateStringFormat.dateFromDateString(employment_start_date)
    const today = new Date()
    if (alreadyStarted && _startDate && _startDate <= today) return
    if (!alreadyStarted && (!_startDate || _startDate >= today)) return

    if (alreadyStarted) {
      setValue('employment_start_date', dateFormat.inputVal(today))
    } else {
      setValue('employment_start_date', '')
    }
  }, [alreadyStarted, setValue, getValues])
  // handle changes to 'employment_start_date' independently of 'already_started'
  React.useEffect(() => {
    if (!startDate) return
    const _alreadyStarted = getValues().already_started
    const today = dateFormat.inputVal(new Date())
    const expectedAlreadyStarted = startDate <= today
    if (_alreadyStarted !== expectedAlreadyStarted) {
      setValue('already_started', expectedAlreadyStarted)
    }
  }, [startDate, setValue, getValues])

  return {
    isComplete: Boolean(knownStartDate),
    onSubmit: handleSubmit(async (values) => {
      if (knownStartDate) throw new Error('Start date already set')
      if (!values.employment_start_date) throw new Error('Start date required')

      updateUser({ employment_start_date: values.employment_start_date })
    }),
    ...form,
    ...mutationState,
  }
}
