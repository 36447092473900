import { Container } from '@mui/material'
import * as React from 'react'
import { useOrgDashboardQuery } from '../../../orgDashboard/homepage/gql/orgDashboard_gen'
import { OrderForm } from './OrderForm'

export const OrderFormPage = () => {
  const { data: orgData } = useOrgDashboardQuery()
  const org = orgData?.organization
  if (!org) return null
  return (
    <Container maxWidth="md">
      <OrderForm org={org} />
    </Container>
  )
}
