import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UkgOnboardMutationVariables = Types.Exact<{
  input: Types.UkgOnboardInput
}>

export type UkgOnboardMutation = {
  __typename?: 'Mutation'
  ukgOnboard?: {
    __typename?: 'UkgOnboardPayload'
    organization?: {
      __typename?: 'Organization'
      id: number
      name: string
      employers: Array<{ __typename?: 'Employer'; id: number; name?: string | null } | null>
    } | null
  } | null
}

export const UkgOnboardDocument = `
    mutation UKGOnboard($input: UkgOnboardInput!) {
  ukgOnboard(input: $input) {
    organization {
      id
      name
      employers {
        id
        name
      }
    }
  }
}
    `

export const useUkgOnboardMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<UkgOnboardMutation, TError, UkgOnboardMutationVariables, TContext>
) => {
  return useMutation<UkgOnboardMutation, TError, UkgOnboardMutationVariables, TContext>(
    ['UKGOnboard'],
    (variables?: UkgOnboardMutationVariables) =>
      fetchGql<UkgOnboardMutation, UkgOnboardMutationVariables>(UkgOnboardDocument, variables)(),
    options
  )
}
