import { Box, PageBody } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { Navigate } from 'react-router'
import { FinchConnectResultAlert } from '../../externalServices/finch/FinchConnectResultAlert'
import { useCurrentUserQuery } from '../../user/gql/currentUser_gen'
import { hasBillingConnected } from '../billing/billingUtils'
import { AliceCardProgramSection } from './AliceCardProgramSection'
import { BillingSection } from './BillingSection'
import { EnrollmentSection } from './EnrollmentSection'
import { useOrgDashboardQuery } from './gql/orgDashboard_gen'
import { OrgHealthcheck } from './OrgHealthcheck'

export const OrgHomepage = () => {
  const { data: userData } = useCurrentUserQuery()
  const { data: orgData } = useOrgDashboardQuery()
  const user = userData?.currentUser
  const org = orgData?.organization

  if (!user || !org) return null

  return (
    <PageBody maxWidth={800}>
      <EmployerHomepageOnboardingRedirect />
      <OrgHealthcheck org={org} />
      <FinchConnectResultAlert />
      {org && !hasBillingConnected(org) && (
        <Box my={4}>
          <BillingSection org={org} />
          {/* future work will display latest invoice & status */}
        </Box>
      )}
      <Box my={4}>
        <AliceCardProgramSection org={org} />
      </Box>
      <Box my={4}>
        <EnrollmentSection org={org} />
      </Box>
    </PageBody>
  )
}

/**
 * This component will handle cases where a user is sent to the employer homepage at /manage
 * from inside this web app. The main UI is handled by Ada, but before handing off, the
 * web app needs to check if the user should be sent to the onboarding flow. If so, the
 * redirect happens immediately. If not, the whole page is refreshed so that the request
 * will go through the reverse proxy and render Ada.
 */
export const EmployerHomepageOnboardingRedirect = () => {
  const { data: orgData } = useOrgDashboardQuery()
  const isOnboarding = Boolean(!orgData?.organization)
  const needsOrderForm = !orgData?.organization?.onboardingState.isDoneWithOnboardingDocuments

  // If user is onboarding, they should not see the dashboard
  if (isOnboarding) {
    return <Navigate to="/onboard" replace />
  } else if (needsOrderForm) {
    return <Navigate to="/onboard/order-form" replace />
  }

  return null
}
