import { useMutationOptionsWithInvalidation } from '@alice-financial/api'
import {
  ActionLayout,
  Box,
  Button,
  Grid,
  InlineInputController,
  InputLabel,
  LinearProgress,
  Typography,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useUkgOnboardMutation } from '../gql/ukgOnboard_gen'
import { CompanyInfoUKGHelp } from './CompanyInfoUKGHelp'

// the querystring param required to initiate UKG onboarding
const UKG_PARAM = 'ukgpro-company'

export const useUKGOnboardingCompany = () => {
  const [searchParams] = useSearchParams()
  return searchParams.get(UKG_PARAM)
}

const useConnectUKG = (mutationOptions?: Parameters<typeof useUkgOnboardMutation>[0]) => {
  const _mutationOptions = useMutationOptionsWithInvalidation(mutationOptions, 'all')
  return useUkgOnboardMutation(_mutationOptions)
}

export const CompanyInfoUKGForm = () => {
  const legalName = useUKGOnboardingCompany()
  const { mutate: connectUKG, isLoading, isSuccess } = useConnectUKG()
  const { handleSubmit, control, register } = useForm({
    defaultValues: { clientId: '', clientSecret: '', legalName },
  })
  const disabled = isLoading || isSuccess
  return (
    <form data-testid="create-org-ukg" onSubmit={handleSubmit((values) => connectUKG({ input: values }))}>
      <input type="hidden" {...register('legalName')} />
      <Typography gutterBottom>
        We&apos;ve partnered with UKG to make it easy to connect your payroll. All you need to provide are
        some connection credentials, and we can load the information we need to administer commuter benefits.
      </Typography>
      <Box mb={2}>
        <CompanyInfoUKGHelp>Find out where to get this info in UKGPro</CompanyInfoUKGHelp>
      </Box>
      <Typography fontWeight="bold">We will automatically sync:</Typography>
      <ul>
        <li>
          <Typography>Company legal name</Typography>
        </li>
        <li>
          <Typography>Company address</Typography>
        </li>
        <li>
          <Typography>Location/payroll information</Typography>
        </li>
        <li>
          <Typography>Employee roster</Typography>
        </li>
      </ul>
      <Grid container spacing={1} rowGap={2} justifyContent="space-evenly" sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={5}>
          <InputLabel sx={{ display: 'block', marginBottom: 2 }} htmlFor="legal_name">
            Client ID
          </InputLabel>
          <InlineInputController
            control={control}
            name="clientId"
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <InputLabel sx={{ display: 'block', marginBottom: 2 }} htmlFor="legal_name">
            Client secret
          </InputLabel>
          <InlineInputController
            control={control}
            name="clientSecret"
            type="password"
            disabled={disabled}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
      </Grid>
      <ActionLayout
        mb={0}
        primary={
          <>
            <Button type="submit" variant="contained" fullWidth disabled={disabled}>
              {isLoading ? 'Loading info...' : 'Connect to UKGPro'}
            </Button>
            {isLoading && (
              <Typography variant="caption" textAlign="center" component="p">
                This may take a few minutes
              </Typography>
            )}
          </>
        }
      />
      {isLoading && (
        <Box pt={2} width="100%">
          <LinearProgress />
        </Box>
      )}
    </form>
  )
}
