import { Grid, SectionHeading, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { isNotNull } from '../../../utils/typeUtils'
import { PaygroupList } from './PaygroupList'
import { OrgSectionProps } from './types'

export const EnrollmentSection = ({ org }: OrgSectionProps) => {
  if (!org) return null
  // if (!hasConnectedPayroll) return (<Section><SectionHeading>Connect payroll</SectionHeading></Section>)

  const employers = org.employers.filter(isNotNull)
  if (employers.length === 0) return null
  const { totalSavings } = org.employees

  return (
    <>
      <SectionHeading gutterBottom>
        <FormattedMessage id="admin.enrollment.heading" />
      </SectionHeading>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">
            Payroll provider: <strong>{org.payrollConnection.payrollPlatform?.name}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="text.secondary">
            <strong>{org.employees.enrolledCount}</strong>/{org.employees.reachableCount} enrolled
          </Typography>
        </Grid>
      </Grid>
      {totalSavings ? (
        <Typography variant="body2" color="primary" gutterBottom>
          <strong>{formatCentsAsDollars(totalSavings)}</strong> total savings
        </Typography>
      ) : null}

      <Typography variant="h4">Pay groups</Typography>
      <PaygroupList employers={employers} />
    </>
  )
}
