import { Container, InfoDrawer, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { ContactSupport } from '../../../employeeDashboard/ContactSupport'

const ANCHOR_NAME = 'ukg-connect-instructions'

const InfoContent = () => (
  <>
    <Container>
      <Typography gutterBottom>
        To give access to your company&apos;s payroll to a third party via API in UKG Pro, you will need to
        create a Service Account.
      </Typography>

      <ol>
        <li>
          Go to <strong>Administration</strong> &gt; <strong>Security</strong> &gt;{' '}
          <strong>Service Accounts</strong>.
        </li>
        <li>
          Click <strong>Add Service Account</strong>.
        </li>
        <li>Enter a name and description for the Service Account.</li>
        <li>
          Select the <strong>Payroll</strong> permission set.
        </li>
        <li>
          Click <strong>Create</strong>.
        </li>
      </ol>

      <Typography gutterBottom>
        Once you have created a Service Account, you will need to generate a Client ID and Client Secret.
        These credentials will be used by Alice to authenticate and authorize its requests to the UKG Pro API.
      </Typography>

      <Typography gutterBottom>To generate a Client ID and Client Secret:</Typography>

      <ol>
        <li>
          <Typography>
            Click on the name of the Service Account that you created in the previous step.
          </Typography>
        </li>
        <li>
          <Typography>
            Click <strong>Generate Client ID and Client Secret</strong>.
          </Typography>
        </li>
        <li>
          <Typography>
            Click <strong>Yes</strong> to confirm that you want to generate new credentials.
          </Typography>
        </li>
        <li>
          <Typography>Copy the Client ID and Client Secret to a safe place.</Typography>
        </li>
      </ol>

      <Typography gutterBottom>
        Once you have generated a Client ID and Client Secret, you can enter them into the signup form. Alice
        will use these credentials to securely access your company&apos;s payroll data in UKG Pro.
      </Typography>
    </Container>
    <ContactSupport />
  </>
)

type CompanyInfoUKGHelpProps = {
  children: React.ReactNode
}
export const CompanyInfoUKGHelp = ({ children }: CompanyInfoUKGHelpProps) => {
  return (
    <InfoDrawer
      title={<Typography variant="h2">How to connect your UKGPro account</Typography>}
      anchorName={ANCHOR_NAME}
      infoContent={<InfoContent />}
    >
      {children}
    </InfoDrawer>
  )
}
