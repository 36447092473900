import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useOrgDashboardQuery } from '../../homepage/gql/orgDashboard_gen'
import { useCreatePaymentMethodConnectionMutation } from './gql/createPaymentMethodConnection_gen'
import { TypedBankableEntity } from './types'

type UseConnectPaymentMethodMutationOptions = UseGqlMutationCallbacks<unknown, { paymentMethodId: number }>
export const useConnectPaymentMethod = (
  { owner }: { owner: TypedBankableEntity },
  mutationOptions?: UseConnectPaymentMethodMutationOptions
) => {
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(mutationOptions, [
    useOrgDashboardQuery.getKey(),
  ])
  const { mutateAsync: createPaymentMethodConnection } = useCreatePaymentMethodConnectionMutation()
  return useMutation(
    ['createPaymentMethodConnection'],
    ({ paymentMethodId }) =>
      createPaymentMethodConnection({ input: { ownerId: owner.id, ownerType: owner.type, paymentMethodId } }),
    mutationOptionsWithInvalidation
  )
}
