import { useIntl } from 'react-intl'
import {
  Button,
  Container,
  FullDialog,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@alice-financial/pretext-ui'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import * as React from 'react'
import { dateStringFormat } from '../../../../utils/formatters/dateFormat'
import { EmployeeDocument } from './types'

const DocumentListContent = ({ doc }: { doc?: Pick<EmployeeDocument, 'name' | 'signed_at'> }) => {
  if (!doc) return null
  return (
    <>
      <ListItemText
        primary={doc.name}
        primaryTypographyProps={{ lineHeight: 1.2 }}
        secondary={doc.signed_at ? `signed ${dateStringFormat.medium(doc.signed_at)}` : null}
      />
      <OpenInNewOutlinedIcon color="primary" />
    </>
  )
}

const Form5500ListItem = ({ doc }: { doc: EmployeeDocument }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  if (!doc) return null

  const onAccept = () => {
    handleClose()
    window.open(`/docs/${doc.id}`, '_blank')
  }

  const actions = (
    <Button autoFocus onClick={onAccept}>
      Accept
    </Button>
  )
  const form5500Paragraphs = [
    'The data in this report refers to those benefits for which Alice provides administrative and claims processing services.',
    'Any other benefits that you offer to your employees will NOT be reflected in this report.',
    'You may have additional benefits subject to Form 5500 filing and should refer to your Plan Administrator or Sponsor for additional guidance.',
    'You are responsible for confirming the accuracy of this data with your Plan Administrator or Sponsor before filing Form 5500 with the IRS.',
    'Alice does not provide legal, account or tax advice and does not serve as the Plan Administrator or Plan Sponsor for Your Plan.',
  ]

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <DocumentListContent doc={doc} />
      </ListItemButton>
      <FullDialog open={open} title={<>Form 5500 Disclaimers</>} onClose={handleClose} actions={actions}>
        <Container>
          {form5500Paragraphs.map((p, idx) => (
            <Typography gutterBottom key={idx}>
              {p}
            </Typography>
          ))}
        </Container>
      </FullDialog>
    </>
  )
}

type DocumentListProps = { documents: Array<EmployeeDocument> }
export const DocumentList = ({ documents }: DocumentListProps) => {
  const isForm5500 = (doc: EmployeeDocument) => doc.name === 'Form 5500'
  const form5500Docs = documents
    .filter(isForm5500)
    .sort((a, b) => (a.version && b.version && a.version < b.version ? 1 : -1))
  const latestForm5500 = form5500Docs[0]
  const otherDocs = documents.filter((doc) => !isForm5500(doc))
  const intl = useIntl()

  return (
    <List>
      {latestForm5500 && <Form5500ListItem doc={latestForm5500} />}

      <ListItem disablePadding>
        <ListItemButton href="https://stripe.com/issuing/e-sign-disclosure/legal" target="_blank">
          <DocumentListContent
            doc={{ name: intl.formatMessage({ id: 'cards.alice.stripe-card-agreement' }) }}
          />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton href="https://stripe.com/legal/issuing/celtic-authorized-user-terms" target="_blank">
          <DocumentListContent
            doc={{ name: intl.formatMessage({ id: 'cards.alice.stripe-card-user-terms' }) }}
          />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton
          href="https://stripe.com/legal/issuing/celtic/apple-payment-platform-program-manager-customer-terms-and-conditions"
          target="_blank"
        >
          <DocumentListContent doc={{ name: intl.formatMessage({ id: 'cards.alice.apple-pay-terms' }) }} />
        </ListItemButton>
      </ListItem>

      {otherDocs &&
        otherDocs.map((doc) => (
          <ListItem key={doc.id} disablePadding>
            <ListItemButton href={`docs/${doc.id}`} target="_blank">
              <DocumentListContent doc={doc} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  )
}
