import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from '@alice-financial/pretext-ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Elements } from '@stripe/react-stripe-js'
import * as React from 'react'
import { PaymentCardInput } from '../../externalServices/stripe/PaymentCardInput'
import { useBillingStripe } from '../../externalServices/stripe/useLoadStripe'
import { ACHBillingSourceInput } from './ACHBillingSourceInput'
import { ConnectBillingSourceProps, Billable } from './types'
import { ACHNumbers } from '../../orgDashboard/banking/types'
import { useBillingClientSecret } from './useBillingClientSecret'
import { ACHMandate } from '../../externalServices/stripe/ACHMandate'
import { ConnectACHForm } from '../banking/ConnectACHForm'
import { useSetBankingAccount } from './useSetBankingAccount'
import { getBillableType, billingWorkspaceRequiresMicrodeposits } from './billingUtils'
import { useOrgDashboardQuery } from '../homepage/gql/orgDashboard_gen'

type PaymentOptionProps = ConnectBillingSourceProps &
  Pick<AccordionProps, 'expanded' | 'disabled' | 'onChange'>
const PaymentCardOption = ({
  billable,
  onSuccess,
  ...accordionProps
}: Omit<PaymentOptionProps, 'onError' | 'currentBillingSource'>) => (
  <Accordion {...accordionProps}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Enter credit card details
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <PaymentCardInput billable={billable} onSuccess={onSuccess} />
    </AccordionDetails>
  </Accordion>
)

/* 
  "Secret" component for re-accepting ACH mandate. Not clear yet how often we'll want or need to do this,
  so for now it will remain hidden and only used by Alicians. If a customer finds this, no real harm, just will be confusing.
  Only applicable if current payment method is not null AND is a US bank account.
*/
type SecretACHMandateReacceptanceProps = { billable: Billable; clientSecret: string }
const SecretACHMandateReacceptance = ({ billable, clientSecret }: SecretACHMandateReacceptanceProps) => {
  const [showMandate, setShowMandate] = React.useState(false)
  const clickShowMandate = () => {
    setShowMandate(true)
  }
  return (
    <>
      <Typography color="#fff" onClick={clickShowMandate} variant="caption">
        π
      </Typography>
      {showMandate && (
        <ACHMandate
          clientSecret={clientSecret}
          handleConfirmationResponse={() => {
            setShowMandate(false)
          }}
          invalidationKeys={[useBillingClientSecret.getKey(billable)]}
        />
      )}
    </>
  )
}

const StripeConnectOption = ({
  billable,
  clientSecret,
  currentBillingSource,
  onSuccess,
  onBankSelected,
  ...accordionProps
}: Omit<PaymentOptionProps, 'onError'> & { clientSecret: string; onBankSelected: () => void }) => {
  return (
    <Accordion {...accordionProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        <Typography variant="body2" color="primary" fontWeight="bold">
          Connect with bank login
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ACHBillingSourceInput
          currentBillingSource={currentBillingSource}
          clientSecret={clientSecret}
          billable={billable}
          onSuccess={onSuccess}
          onBankSelected={onBankSelected}
        />
        {currentBillingSource && (
          <SecretACHMandateReacceptance billable={billable} clientSecret={clientSecret} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

const BankAccountOption = ({
  billable,
  clientSecret,
  currentBillingSource,
  onSuccess,
  onBankSelected,
  onError,
  ...accordionProps
}: PaymentOptionProps & { clientSecret: string; onBankSelected: () => void }) => {
  const [showACHMandate, setShowACHMandate] = React.useState(false)
  const [routingNumber, setRoutingNumber] = React.useState<string | null>(null)
  const [accountNumber, setAccountNumber] = React.useState<string | null>(null)
  const { mutate: setBankingAccount } = useSetBankingAccount(
    { ownerId: billable.id, ownerType: getBillableType(billable), routingNumber, accountNumber },
    [useOrgDashboardQuery.getKey()], // eventually we might need to invalidate a billable-specific query
    { onSuccess }
  )
  return (
    <Accordion {...accordionProps}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        <Typography variant="body2" color="primary" fontWeight="bold">
          Enter bank account and routing number
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {showACHMandate ? (
          <ACHMandate
            clientSecret={clientSecret}
            handleConfirmationResponse={setBankingAccount}
            invalidationKeys={[useBillingClientSecret.getKey(billable)]}
          />
        ) : (
          <>
            <ConnectACHForm
              clientSecret={clientSecret}
              onError={onError}
              onSuccess={(achNumbers: ACHNumbers) => {
                setRoutingNumber(achNumbers.routingNumber)
                setAccountNumber(achNumbers.accountNumber)
                onBankSelected()
                setShowACHMandate(true)
              }}
            />
            {currentBillingSource && (
              <SecretACHMandateReacceptance billable={billable} clientSecret={clientSecret} />
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

type PaymentOption = 'card' | 'bank_credentials' | 'stripe_connect'
export const ConnectBillingSource = ({
  currentBillingSource,
  billable,
  onSuccess,
}: Omit<ConnectBillingSourceProps, 'onError'>) => {
  const [expandedOption, setExpandedOption] = React.useState<PaymentOption | null>(null)
  const [isBankSelected, setIsBankSelected] = React.useState(false)
  const [isStripeConnectSelected, setStripeConnectSelected] = React.useState(false)
  const billingWorkspace = billable?.billing?.billingWorkspace
  const stripePromise = useBillingStripe(billingWorkspace)
  const { data: clientSecret, refetch } = useBillingClientSecret(billable, currentBillingSource)
  const noMicroDeposits = !billingWorkspaceRequiresMicrodeposits(billingWorkspace)

  if (!clientSecret) return null

  return (
    <>
      <Container>
        <Typography variant="body2" gutterBottom>
          Choose how you would like to connect the account we should bill for <strong>{billable.name}</strong>
        </Typography>
        <Box pb={3}>
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            {!isBankSelected && !isStripeConnectSelected && (
              <PaymentCardOption
                billable={billable}
                onSuccess={onSuccess}
                expanded={expandedOption === 'card'}
                onChange={(_, isExpanded) => setExpandedOption(isExpanded ? 'card' : null)}
              />
            )}
            {noMicroDeposits && !isStripeConnectSelected && (
              <BankAccountOption
                currentBillingSource={currentBillingSource}
                billable={billable}
                clientSecret={clientSecret}
                onSuccess={() => {
                  onSuccess()
                  setIsBankSelected(false) // once ACH Mandate is accepted, restore visibility of other panels
                }}
                onError={() => refetch()}
                expanded={expandedOption === 'bank_credentials'}
                onChange={(_, isExpanded) => setExpandedOption(isExpanded ? 'bank_credentials' : null)}
                onBankSelected={() => setIsBankSelected(true)}
              />
            )}
            {!isBankSelected && (
              <StripeConnectOption
                currentBillingSource={currentBillingSource}
                billable={billable}
                clientSecret={clientSecret}
                onSuccess={() => {
                  onSuccess()
                  setStripeConnectSelected(false) // once ACH Mandate is accepted, restore visibility of other panels
                }}
                expanded={expandedOption === 'stripe_connect'}
                onChange={(_, isExpanded) => setExpandedOption(isExpanded ? 'stripe_connect' : null)}
                onBankSelected={() => setStripeConnectSelected(true)}
              />
            )}
          </Elements>
        </Box>
      </Container>
    </>
  )
}
