import {
  ActionLayout,
  CheckboxController,
  ExternalLink,
  InlineInputController,
  PageBody,
  useResponseNotification,
} from '@alice-financial/pretext-ui'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router'
import { AddressDisplay } from '../../../employeeDashboard/profile/address/AddressDisplay'
import { OrganizationDetailFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { SubmitOnboardingDocumentsInput } from '../../../graphql/generated.types'
import { useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { addressAttributesToAddressDisplay } from '../addressAttributesToAddressDisplay'
import { useQueryProvisionalOrgs } from '../useQueryProvisionalOrgs'
import { useSubmitOnboardingDocuments } from '../useSubmitOnboardingDocuments'

type OrderFormProps = {
  org: OrganizationDetailFragment
}

// these are dummy values for the form fields
type OrderFormValues = Omit<SubmitOnboardingDocumentsInput, 'orgId'> & {
  date: string
  twoYearContract: boolean
  heroSignature: string
  heroTitle: string
}

export const OrderForm = ({ org }: OrderFormProps) => {
  const { control, handleSubmit } = useForm<OrderFormValues>({
    defaultValues: {
      orgTosAccepted: false,
      onboardingTimelineAccepted: false,
      esignConsentAccepted: false,
      isReferenceCustomer: false,
      twoYearContract: false,
      ownerSignature: '',
      ownerTitle: '',
      date: new Date().toLocaleDateString(),
      heroSignature: 'Avinash Karnani',
      heroTitle: 'Founder and CEO',
    },
  })
  const { data: currentUserData } = useCurrentUserQuery()
  const { data: provisionalOrg } = useQueryProvisionalOrgs()
  const hasCompletedOrderForm = org.onboardingState.isDoneWithOnboardingDocuments
  const currentUser = currentUserData?.currentUser
  const { notifySuccess } = useResponseNotification()
  const { mutate: submitDocuments } = useSubmitOnboardingDocuments({
    onSuccess: () => notifySuccess('Order form submitted!'),
  })

  const onSubmitOnboardingDocuments = handleSubmit((values: OrderFormValues) => {
    const { heroSignature, heroTitle, date, twoYearContract, ...mutationValues } = values
    const contractLength = twoYearContract ? 24 : 12
    const vals = { orgId: org.id, contractLength, ...mutationValues }
    submitDocuments(vals)
  })

  if (hasCompletedOrderForm) return <Navigate to="/manage" />

  return (
    <PageBody>
      <Container maxWidth="md">
        <form onSubmit={onSubmitOnboardingDocuments}>
          <Typography variant="h1" color="primary" gutterBottom>
            <FormattedMessage id="organization.onboarding.order_form_heading" />
          </Typography>
          <Divider />
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h3" color="primary" mb={0}>
                Client administrator information
              </Typography>
              <Divider />
              <Box>
                <Typography>
                  Name: {currentUser?.firstName} {currentUser?.lastName}
                </Typography>
                <Typography>Email: {currentUser?.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h3" color="primary" mb={0}>
                Client information
              </Typography>
              <Divider />
              <Box>
                <Typography>Name: {org.name}</Typography>
                <Typography>Payroll Platform: {org?.payrollConnection?.payrollPlatform?.name}</Typography>
                <Typography>
                  Company Address:{' '}
                  <AddressDisplay address={addressAttributesToAddressDisplay(provisionalOrg?.address)} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" mb={3}>
                Please select all that apply
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <CheckboxController
                color="primary"
                name="esignConsentAccepted"
                control={control}
                required
                label={
                  <FormattedMessage
                    id="enroll.register.legal_agreement.i_have_read_and_agree"
                    values={{
                      'esign-consent': () => (
                        <ExternalLink target="_blank" href="/docs/esign-consent">
                          <FormattedMessage id="enroll.register.legal_agreement.esign_disclosure_and_consent" />
                        </ExternalLink>
                      ),
                      'terms-and-conditions': () => (
                        <ExternalLink target="_blank" href="/docs/tos-employee">
                          <FormattedMessage id="enroll.register.legal_agreement.terms_and_conditions" />
                        </ExternalLink>
                      ),
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxController
                color="primary"
                name="orgTosAccepted"
                required
                control={control}
                label={
                  <FormattedMessage
                    id="organization.onboarding.accept_employer_tos"
                    values={{
                      'employer-tos': (chunks) => (
                        <ExternalLink target="_blank" href="/docs/tos-organization">
                          {chunks}
                        </ExternalLink>
                      ),
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxController
                color="primary"
                name="onboardingTimelineAccepted"
                control={control}
                required
                label={<FormattedMessage id="onboarding.condition.accept_onboarding_timeline" />}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxController
                color="primary"
                name="twoYearContract"
                control={control}
                label={
                  <Typography variant="body2">
                    <FormattedMessage id="onboarding.offer.two_year_contract_offer" />
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxController
                color="primary"
                name="isReferenceCustomer"
                control={control}
                label={
                  <Typography variant="body2">
                    <FormattedMessage id="onboarding.offer.reference_customer_offer" />
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" mb={2} fontStyle="italic">
                Client shall pay Alice a Service Fee equal to Client&apos;s Payroll Tax Savings each pay
                period.
              </Typography>
            </Grid>
            <Divider />
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <InlineInputController
                  control={control}
                  label="Signature"
                  name="ownerSignature"
                  fullWidth
                  required
                  inputProps={{
                    style: { fontFamily: 'cursive' },
                  }}
                />
              </Box>
              <Box mb={2}>
                <InlineInputController
                  control={control}
                  name="ownerTitle"
                  label="Title"
                  fullWidth
                  required
                  inputProps={{
                    style: { fontFamily: 'cursive' },
                  }}
                />
              </Box>
              <Box mb={2}>
                <InlineInputController
                  control={control}
                  label="Date"
                  name="date"
                  disabled
                  inputProps={{
                    style: { fontFamily: 'cursive' },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <InlineInputController
                  control={control}
                  label="Pretax Hero Inc. (Alice)"
                  fullWidth
                  name="heroSignature"
                  disabled
                  inputProps={{
                    style: { fontFamily: 'cursive' },
                  }}
                />
              </Box>
              <Box mb={2}>
                <InlineInputController
                  control={control}
                  label="Title"
                  fullWidth
                  disabled
                  name="heroTitle"
                  inputProps={{
                    style: { fontFamily: 'cursive' },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <ActionLayout
            primary={
              <Button type="submit" variant="contained" size="medium">
                Accept and Submit
              </Button>
            }
            mt={0}
            autoWidth
            disablePadding
          />
        </form>
      </Container>
    </PageBody>
  )
}
