import { useMutation } from '@tanstack/react-query'
import {
  SubmitOnboardingDocumentsMutation,
  useSubmitOnboardingDocumentsMutation,
} from './gql/submitOnboardingDocuments_gen'
import { SubmitOnboardingDocumentsInput } from '../../graphql/generated.types'
import { UseGqlMutationCallbacks, useMutationOptionsWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { useOrgDashboardQuery } from '../../orgDashboard/homepage/gql/orgDashboard_gen'

type UseSubmitOnboardingDocumentsOptions = UseGqlMutationCallbacks<
  SubmitOnboardingDocumentsMutation,
  SubmitOnboardingDocumentsInput
>

export const useSubmitOnboardingDocuments = (mutationOptions?: UseSubmitOnboardingDocumentsOptions) => {
  const mutationOptionsWithInvalidation = useMutationOptionsWithInvalidation(
    useMutationNotifier({}, mutationOptions),
    [useOrgDashboardQuery.getKey()]
  )

  const { mutateAsync: submitDocuments } = useSubmitOnboardingDocumentsMutation()

  return useMutation(
    ['submitOnboardingDocuments'],
    (values: SubmitOnboardingDocumentsInput) => submitDocuments({ input: values }),
    mutationOptionsWithInvalidation
  )
}
