import { HealthcheckItem, InertHealthcheckItem } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'

type OrgLogoHealthcheckProps = {
  org?: OrganizationDetailFragment | null
}
export const OrgLogoHealthcheck = ({ org }: OrgLogoHealthcheckProps) => {
  if (!org) return null
  if (org.onboardingState.isAlreadyLaunched) return null
  const logoRequirementSatisfied =
    org.onboardingState.isLogoPresent || org.workLocations.every((location) => location?.logo)

  return logoRequirementSatisfied ? (
    <InertHealthcheckItem
      primary="Organization logo uploaded"
      secondary="Enrollment materials will include a logo your employees recognize"
      status="complete"
    />
  ) : (
    <HealthcheckItem
      primary="Organization logo required"
      secondary="Enrollment materials need a logo your employees recognize"
      status="incomplete"
      to="/manage/locations"
    />
  )
}
