import {
  Box,
  Callout,
  Container,
  Link,
  PageBody,
  PaperSection,
  Typography,
  alpha,
} from '@alice-financial/pretext-ui'
import { FormatXMLElementFn } from 'intl-messageformat'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Navigate, useNavigate } from 'react-router'
import { TrnKey } from '../../../translations/types'
import hexTile from '../../assets/hex-abstract-tile.png'
import { useHasProfileType, useRequiredProfileType } from '../../user/useProfileType'
import { CompanyInfoStep } from './steps/CompanyInfoStep'
import { CreateOrgAdminStep } from './steps/CreateOrgAdminStep'
import { useOrgDashboardQuery } from '../../orgDashboard/homepage/gql/orgDashboard_gen'

const getExitMessage = (
  hasEmployeeProfile: boolean | undefined,
  hasAdminProfile: boolean | undefined
): TrnKey | null => {
  if (hasEmployeeProfile && hasAdminProfile) return 'organization.onboarding.go_to_org_or_ee_dash'
  if (hasEmployeeProfile) return 'organization.onboarding.go_to_employee_dash'
  if (hasAdminProfile) return 'organization.onboarding.go_to_org_admin_dash'
  return null
}

/**
 * If a user with an existing employee or admin profile finds themselves on the org onboarding page,
 * there is a distinct possibility that they were intending to go to their dashboard and ended up
 * here by mistake. This component provides a callout to help them navigate to the correct page.
 */
const ExitOnboarding = () => {
  const hasEmployeeProfile = useHasProfileType('employee')
  const hasAdminProfile = useHasProfileType('employer_admin', 'organization_admin')
  const values: Record<string, FormatXMLElementFn<React.ReactNode, React.ReactNode>> = {
    'employee-dashboard-link': (...chunks) => <Link to="/dashboard">{chunks}</Link>,
    'org-admin-dashboard-link': (...chunks) => <Link to="/manage">{chunks}</Link>,
  }
  const trnKey = getExitMessage(hasEmployeeProfile, hasAdminProfile)
  if (!trnKey) return null
  return (
    <Callout>
      <FormattedMessage id={trnKey} values={values} />
    </Callout>
  )
}

export const OrgOnboarding = () => {
  const navigate = useNavigate()
  const { data: orgData } = useOrgDashboardQuery()
  const org = orgData?.organization
  const hasSetUpValidOrg = org && org.employers.length > 0
  const hasSubmittedOnboardingDocuments = org?.onboardingState.isDoneWithOnboardingDocuments
  const authorizedUserProfileType = useRequiredProfileType('provisional_admin')
  React.useEffect(() => {
    if (hasSetUpValidOrg && !hasSubmittedOnboardingDocuments) {
      navigate('/onboard/order-form')
    } else if (hasSubmittedOnboardingDocuments) {
      navigate('/manage')
    }
  }, [hasSetUpValidOrg, hasSubmittedOnboardingDocuments, navigate])

  if (authorizedUserProfileType === null) return <Navigate to="/" replace />
  return (
    <PageBody style={{ background: `${alpha('#b38736', 0.1)} url(${hexTile}) 0 / 192px` }}>
      <PaperSection>
        <Container>
          <Box mb={3}>
            <Typography variant="h1">
              <FormattedMessage id="organization.onboarding.heading" />
            </Typography>
            <ExitOnboarding />
          </Box>
          <Typography gutterBottom>
            <FormattedMessage id="organization.onboarding.excited_to_start" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="organization.onboarding.first_step_account" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="organization.onboarding.set_up_company" />
          </Typography>
          <Typography gutterBottom>
            <FormattedMessage id="organization.onboarding.get_started" />
          </Typography>
        </Container>
      </PaperSection>
      <PaperSection stepNumber={1}>
        <CreateOrgAdminStep />
      </PaperSection>
      <PaperSection stepNumber={2}>
        <CompanyInfoStep />
      </PaperSection>
    </PageBody>
  )
}
