import { Link, List, ListItem, ListItemButton, ListItemText, Typography } from '@alice-financial/pretext-ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import * as React from 'react'
import { formatCentsAsDollars } from '../../../utils/formatters/moneyFormat'
import { EmployerSummaryFragment } from '../../graphql/fragments/OrganizationFragment_gen'

const PaygroupListItem = ({ employer }: { employer: EmployerSummaryFragment }) => {
  const employees = employer.employees
  return (
    <ListItem key={employer.id} disableGutters disablePadding divider>
      <ListItemButton component={Link} to={employer.id.toString() || '#'} disabled={!employer.inviteCode}>
        <ListItemText
          primary={
            <Typography color="primary" fontWeight="bold">
              {employer.name}
            </Typography>
          }
          secondary={
            employees.totalSavings > 0 ? (
              <Typography variant="body2" color="primary">
                <strong>{formatCentsAsDollars(employer.employees.totalSavings)}</strong> saved
              </Typography>
            ) : undefined
          }
        />
        <Typography variant="body2" color="text.secondary">
          <strong>{employer.employees.enrolledCount}</strong>/{employer.employees.reachableCount} enrolled
        </Typography>

        <ChevronRightIcon color="primary" />
      </ListItemButton>
    </ListItem>
  )
}

type PaygroupListProps = {
  employers: Array<EmployerSummaryFragment>
}
export const PaygroupList = ({ employers }: PaygroupListProps) => {
  return (
    <List dense>
      {employers.map((employer) => (
        <PaygroupListItem key={employer.id} employer={employer} />
      ))}
    </List>
  )
}
