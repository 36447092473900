import * as React from 'react'
import { isNotNull } from '../../../utils/typeUtils'
import { OrganizationDetailFragment } from '../../graphql/fragments/OrganizationFragment_gen'
import { OrganizationBilling } from './OrganizationBilling'
import { PaygroupBillingList } from './PaygroupBilling'
import { UnifiedBillingForm } from './UnifiedBillingForm'

type ConnectBillingProps = {
  org: OrganizationDetailFragment
}
/**
 * Component for connecting billing methods to an organization - mainly responsible
 * for setting hasUnifiedBilling and updating UI in response
 */
export const ConnectBilling = ({ org }: ConnectBillingProps) => {
  return (
    <>
      <UnifiedBillingForm />
      {org.billing?.hasUnifiedBilling ? (
        <OrganizationBilling org={org} />
      ) : (
        <PaygroupBillingList employers={org.employers.filter(isNotNull)} />
      )}
    </>
  )
}
